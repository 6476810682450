import React, {useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Form,
    Button, Alert
} from "react-bootstrap";
import {Helmet} from "react-helmet";
import { getAuth, confirmPasswordReset, applyActionCode  } from "firebase/auth";
import app from "../firebaseConfig";
import {useNavigate, useLocation, Link} from "react-router-dom";
import {CheckCircle, XCircle} from "react-bootstrap-icons";


const Action = () => {

    const [validated, setValidated] = useState(false);
    const [formSubmissionError, setFormSubmissionError] = useState(false)
    const [formSubmissionSuccess, setFormSubmissionSuccess] = useState(false)
    const [emailVerified, setEmailVerified] = useState(false)
    const [emailVerifiedError, setEmailVerifiedError] = useState(false)
    const auth = getAuth();
    let user
    const navigate = useNavigate()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('oobCode');
    const mode = queryParams.get('mode');

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        setValidated(true);
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            //set email and password field values to variables
            const newPassword = form.password.value;
            confirmPasswordReset (auth, code, newPassword )
                .then(() => {
                    // Signed up
                    setFormSubmissionSuccess(true)
                    navigate('/login?passwordReset=true')

                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log(error)
                    setFormSubmissionError(true)
                })
    }
}

    useEffect(() => {
        if(mode === 'verifyEmail') {
            applyActionCode(auth, code)
                .then(() => {
                    // Signed up
                    console.log('Email verified successfully')
                    setEmailVerified(true);
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log(error)
                    setEmailVerifiedError(true)
                })
        }
    }, []);


if(mode === 'resetPassword'){
    return (
        <Container>
            <Helmet>
                <title>Reset Password : Upright Bites</title>
            </Helmet>
            <Row className="d-flex align-items-center justify-content-center mt-2">
                <Col>
                    <h1>Reset Password</h1>
                    {formSubmissionError &&
                    <Alert variant={"danger"}>An error occurred, please try again.</Alert>
                        }
                    <p>
                        Enter your new password.
                    </p>
                </Col>

            </Row>
            <Row>
                <Col lg={6}>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control required type="password" placeholder="Password" name={"password"} pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[\d!@#$%^&*]).{8,}"/>
                            <Form.Text id="passwordHelpBlock" muted>
                                8+ characters, at least 1 upper case and lower case letter and at least 1 number or special character.
                            </Form.Text>
                            <Form.Control.Feedback type="invalid">
                                Please enter a valid password.
                            </Form.Control.Feedback>
                        </Form.Group>
                            <Button className={"mb-3"} variant="primary" type="submit">
                                Reset Password
                            </Button>
                        </Form>
                </Col>
            </Row>
        </Container>
);
}

    if(mode === 'verifyEmail') {
        return (
            <Container>
                <Helmet>
                    <title>Email Verified : Upright Bites</title>
                </Helmet>
                <Row className="d-flex align-items-center justify-content-center mt-2">
                    <Col>
                        {emailVerified &&
                            <>
                                <h1 className={"text-success"}><CheckCircle></CheckCircle> Email Verified Successfully
                                </h1>
                                <p>
                                    Your email has been verified.
                                </p>
                                <p>Hungry? Checkout the amazing local <Link to={'/order'}>restaurants serving
                                    your neighborhood!</Link></p>
                            </>
                        }
                        {emailVerifiedError &&
                            <>
                                <h1 className={"text-danger"}><XCircle></XCircle> Email Verification Failed</h1>
                        <p>
                            An error occurred while verifying your email. Please try again.
                        </p>
                        </>
                        }
                    </Col>
                </Row>
            </Container>
        );

    }

}

export default Action;

