import React, { useState, useEffect } from "react";
import {useNavigate, useParams, Link, useLocation} from "react-router-dom";
import {Container, Row, Col, FormGroup, Form, Button, ProgressBar, Table, Modal, Spinner, Alert} from "react-bootstrap";
import {Helmet} from "react-helmet";
import DirectDineStepsPage from "./directdineStepsPage";

const DirectDineCustomerAbout = () => {
    return (
        <Container>
            <Helmet>
                <title>Upright Bites: Direct Dine Program</title>
                <meta name="robots" content="noindex"/>
            </Helmet>
            <Row className="d-flex justify-content-center align-items-center">
            <Col lg={8}>
            <h1>Direct Dine Program</h1>
            <p>Order directly from select restaurants' websites and have Upright Bites' dedicated employees safely handle the delivery.</p>
            <p>Find a participating restaurant on our <Link to={'/order'}>order page</Link>, visit the restaurant's preferred ordering platform to browse the menu, add items to your cart and checkout and then return to Upright Bites to confirm your order details and pay the $5 delivery fee (free for your first order).</p>
                </Col>
            </Row>
    <Row className="d-flex justify-content-center bg-light align-items-center">
        <Col lg={8}>
        <h2>Frequently Asked Questions</h2><br />
            <div>
                <b>What is the Direct Dine program?</b>
                <p>
                    The Direct Dine program allows you to order directly from select restaurants and have Upright Bites handle the delivery. You save money on delivery fees, and the restaurants retain more of their revenue.
                </p>
            </div>
            <div>
                <b>How do I place an order through Direct Dine?</b>
                <p>
                    Simply choose a restaurant listed on our <Link to={'/order'}>order page</Link>, visit the restaurant's preferred ordering platform to browse the menu, add items to your cart and checkout and then return to Upright Bites to confirm your order details and pay the $5 delivery fee (free for your first order). We'll take care of the rest.
                </p>
            </div>
            <div>
                <b>Why should I use Direct Dine?</b>
                <p>
                    By using Direct Dine you can: save money on delivery fees, support local restaurants, promote fair delivery practices and help Upright Bites grow.
                </p>
            </div>
            <div>
                <b>Which restaurants are part of the Direct Dine program?</b>
                <p>
                    You can find a list of participating restaurants serving your address on our <Link to={'/order'}>order page</Link>. We are continually adding more restaurants to expand the selection.
                </p>
            </div>
            <div>
                <b>Are there any hidden fees?</b>
                <p>
                    No, just the price of your food, taxes and our $5 delivery fee. Our goal is to create a sustainable and fair delivery system.
                </p>
            </div>
            <div>
                <b>How does Direct Dine support local restaurants?</b>
                <p>
                    By ordering directly from the restaurants, you help them retain more of their revenue. Upright Bites' fair delivery system ensures that both restaurants and delivery workers benefit.
                </p>
            </div>
            <div>
                <b>What areas does Direct Dine currently serve?</b>
                <p>
                    Direct Dine is currently available in select areas, with plans to expand soon. Enter your address on our <Link to={'/order'}>order page</Link> to see if Direct Dine is available in your area.
                </p>
            </div>
                </Col>
            </Row>
        </Container>
    );
};

export default DirectDineCustomerAbout;