import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Home from "./pages/Home";
import RestarauntResults from "./pages/RestarauntResults";
import MenuPage from "./pages/Menu";
import {BrowserRouter, Routes, Route } from "react-router-dom";
import { CartProvider } from 'use-shopping-cart'
import ShoppingCartPage from "./pages/cart";
import CheckoutForm, { Return } from "./pages/checkout";
import VerifyAddress from "./pages/verifyaddress";
import OrderConfirmation from "./pages/orderConfirmation";
import UprightNav from "./components/navbar";
import PartnersPage from "./pages/partners";
import AboutPage from "./pages/about";
import UprightFooter from "./components/footer";
import Register from "./pages/register";
import Account  from "./pages/account";
import Login from "./pages/login";
import ForgotPassword from "./pages/forgotPassword";
import Action from "./pages/action";
import DirectDineVerifyAddress from "./pages/directdineVerifyAddress";
import DirectDineStepsPage from "./pages/directdineStepsPage";
import DirectDinePayment from "./pages/directDinePayment";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import DirectDineOrderConfirmation from "./pages/directDineOrderConfirmation";
import DirectDineCustomerAbout from "./pages/directDineCustomerAbout";
import DirectDineRestaurantAbout from "./pages/directDineRestaurantAbout";
import TermsPage from "./pages/terms";
import PrivacyPage from "./pages/privacy";
import TiktokPixel from "tiktok-pixel";
import LandingPage from "./pages/landingPage";
import LandingPage10011 from "./pages/landingPage10011";


TiktokPixel.init('CQA1F8JC77UDEPK0N91G');


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

export default function App() {
    const [siteConfig, setSiteConfig] = useState({});
    const [operatingStatus, setOperatingStatus] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVICES_DOMAIN}/config/config.json`);
                const config = await response.json();
                    setSiteConfig(config);
                    return config
            } catch (error) {
                console.error('Error fetching config:', error);
                setSiteConfig({
                    "operating_hours": {
                    "open": {
                        "raw": 1100,
                            "formatted": "11:00 AM"
                    },
                    "close": {
                        "raw": 2100,
                            "formatted": "9:00 PM"
                    }
                },
                    "override": {
                    "open": false,
                        "close": false,
                        "message": ""
                }
                })
            }
        };
        const determineOperatingStatus = async (config) => {
            const date = new Date();
            const options = { timeZone: 'America/New_York', hour: '2-digit', minute:'2-digit', hour12: false };
            let strDate = date.toLocaleString('en-US', options);
            strDate = strDate.replace(/:/g, ''); // remove the colon
            let operatingStatus = "open";
            let reason = "";
            let message = "";
            let operatingStatusData = {};
            if (config.override && config.operating_hours) {

                if (!config.override.open && (strDate < config.operating_hours.open.raw || strDate >= config.operating_hours.close.raw)) {
                    operatingStatus = "closed";
                    reason = "hours"
                }
                if (config.override.close) {
                    operatingStatus = "closed";
                    reason = "override"
                    message = config.override.message
                }

                operatingStatusData = {
                    status: operatingStatus,
                    reason: reason,
                    message: message,
                    open: {
                        formatted: config.operating_hours.open.formatted
                    },
                    close: {
                        formatted: config.operating_hours.close.formatted
                    }
                }
                setOperatingStatus(operatingStatusData);
            }
        }
        fetchData().then(config => {
            determineOperatingStatus(config);
        });
    }, []);


    function Redirect({ to }) {
        const navigate = useNavigate();
        React.useEffect(() => {
            navigate(to);
        }, [navigate, to]);

        return null;
    }

    return(
        <BrowserRouter>
            <UprightNav operatingStatus={operatingStatus} />
            <Routes>
                <Route path="/" element={<Home />} strict />
                <Route path="/order" element={<RestarauntResults />} />
                <Route path="/restarauntresults" element={<Redirect to="/order" />} />
                <Route path="/menu/:restaurantId" element={<MenuPage operatingStatus={operatingStatus} />} />
                <Route path="/direct-dine/:restaurantId/verify-address" element={<DirectDineVerifyAddress operatingStatus={operatingStatus} />} />
                <Route path="/direct-dine/:restaurantId" element={<DirectDineVerifyAddress operatingStatus={operatingStatus} />} />
                <Route path="/direct-dine/:restaurantId/step/:directDineStep" element={<DirectDineStepsPage operatingStatus={operatingStatus} />} />
                <Route path="/direct-dine/:restaurantId/payment" element={<DirectDinePayment operatingStatus={operatingStatus} />} />
                <Route path={"/direct-dine/:restaurantId/confirmation"} element={<DirectDineOrderConfirmation />} />
                <Route path="cart" element={<ShoppingCartPage operatingStatus={operatingStatus}/>} />
                <Route path="/checkout/payment" element={<CheckoutForm operatingStatus={operatingStatus}  />} />
                <Route path={"/checkout/address"} element={<VerifyAddress operatingStatus={operatingStatus} />} />
                <Route path={"/checkout"} element={<Redirect to="/checkout/address" />} />
                <Route path={"/checkout/confirmation"} element={<OrderConfirmation />} />
                <Route path={"/partners/join"} element={<PartnersPage />} />
                <Route path={"/partners"} element={<PartnersPage />} />
                <Route path={"/about"} element={<AboutPage />} />
                <Route path={"/register"} element={<Register />} />
                <Route path={"/account"} element={<Account />} />
                <Route path={"/login"} element={<Login />} />
                <Route path={"/action"} element={<Action />} />
                <Route path={"/forgot-password"} element={<ForgotPassword />} />
                <Route path={"/direct-dine/about"} element={<DirectDineCustomerAbout />} />
                <Route path={"/partners/direct-dine"} element={<DirectDineRestaurantAbout />} />
                <Route path="/about-dd" element={<Redirect to="/partners/direct-dine" />} />
                <Route path="/terms" element={<TermsPage />} />
                <Route path="/privacy" element={<PrivacyPage />} />
                <Route path="/order/10016"  element={<LandingPage />} />
                <Route path="/order/10011"  element={<LandingPage10011 />} />
                <Route path={"/postcard"} element={<Redirect to="/order/10016?utm_source=directmail&utm_medium=eddm&utm_campaign=tired&utm_id=C048" />} />
                <Route path={"/postcards"} element={<Redirect to="/order/10016?utm_source=directmail&utm_medium=eddm&utm_campaign=tired&utm_id=C048" />} />
                <Route path={"/post"} element={<Redirect to="/order/10016?utm_source=directmail&utm_medium=eddm&utm_campaign=tired&utm_id=C048" />} />
                <Route path={"/card"} element={<Redirect to="/order/10016?utm_source=directmail&utm_medium=eddm&utm_campaign=tired&utm_id=C048" />} />
            </Routes>
            <UprightFooter />
        </BrowserRouter>
    )
}




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <CartProvider
    mode="payment"
    cartMode="checkout-session"
    stripe={process.env.REACT_APP_STRIPE_PK}
    currency="USD"
    >
    <Elements stripe={stripePromise}>
    <App />
    </Elements>
</CartProvider>
);