import React, {useEffect, useState} from 'react';
import { useNavigate, useLocation, Link } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Form,
    Button
} from "react-bootstrap";
import {Helmet} from "react-helmet";
import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendEmailVerification,
    onAuthStateChanged
} from "firebase/auth";
import TiktokPixel from "tiktok-pixel";

const Register = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [validated, setValidated] = useState(false);
    const [formSubmissionError, setFormSubmissionError] = useState(false)
    const [formSubmissionSuccess, setFormSubmissionSuccess] = useState(false)
    const auth = getAuth();
    let user;

    TiktokPixel.pageView(); // For tracking page view


    const [source, setSource] = useState(location.state?.source);
    console.log('source is' + source);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user && !user.isAnonymous) {
                navigate('/account')
            }
            // Cleanup subscription on unmount
            return () => unsubscribe();
        }, [auth, user]);
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        setValidated(true);
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            //set email and password field values to variables
            const email = form.email.value;
            const password = form.password.value;
            createUserWithEmailAndPassword(auth, email, password )
                .then((userCredential) => {
                    // Signed up
                    const user = userCredential.user;
                    setFormSubmissionSuccess(true)
                    console.log(auth.currentUser)
                    sendEmailVerification(auth.currentUser)
                    TiktokPixel.track("CompleteRegistration"); // For tracking default events. More info about standard events: https://ads.tiktok.com/help/article?aid=10028
                    signInWithEmailAndPassword(auth, email, password)
                        .then(() => {
                            if (source) {
                                navigate(source.toString(), {state: {message: 'You have successfully created an account.'}})
                            } else {
                                navigate('/account')
                            }
                        })
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    setFormSubmissionError(true)
                })
    }
}





    return (
        <Container>
            <Helmet>
                <title>Register : Upright Bites</title>
            </Helmet>
            <Row className="d-flex align-items-center justify-content-center mt-2">
                <Col>
                    <h1>Register</h1>
                    <p>
                        Create an account to start ordering from your favorite restaurants.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="formBasicEmail" >
                                <Form.Label>Email address</Form.Label>
                                <Form.Control required type="email" placeholder="Enter email" name={"email"}/>
                                <Form.Control.Feedback type="invalid">
                                    Please enter a valid email address.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control required type="password" placeholder="Password" name={"password"} pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[\d!@#$%^&*]).{8,}"/>
                                <Form.Text id="passwordHelpBlock" muted>
                                    8+ characters, at least 1 upper case and lower case letter and at least 1 number or special character.
                                </Form.Text>
                                <Form.Control.Feedback type="invalid">
                                    Please enter a valid password.
                                </Form.Control.Feedback>
                            </Form.Group>
                        <p>
                            By registering for an account, you agree to our <Link to={"/terms"}>Terms of Use</Link> and <Link to={"/privacy"}>Privacy Policy</Link>.
                        </p>
                            <Button className={"mb-3"} variant="primary" type="submit">
                                Register
                            </Button>
                        </Form>
                </Col>
            </Row>
        </Container>
);

}

export default Register;

