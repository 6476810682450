import React from 'react';
import {
    Container,
    Row,
    Col,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const TermsPage = () => {

    return (
        <Container>
            <Helmet>
                <title>Terms of Use : Upright Bites</title>
            </Helmet>
            <Row className="d-flex align-items-center justify-content-center mt-2">
                <Col>
                    <div>
                        <h1>Upright Bites Terms of Use</h1>

                        <p>Effective Date: August 29, 2024</p>

                        <h2>Welcome to Upright Bites</h2>
                        <p>
                            These Terms of Use ("Terms") govern your use of the Upright Bites, Inc. platform, including
                            our
                            website, mobile application, and any other services (collectively, the "Services"). By
                            accessing or using the Services, you agree to be bound by these Terms. If you do not agree
                            to these Terms, you may not access or use the Services.
                        </p>

                        <h2>Eligibility</h2>
                        <p>
                            You must be at least 18 years old to use the Services. By accessing or using the Services,
                            you represent and warrant that you are at least 18 years old and have the legal capacity to
                            enter into these Terms.
                        </p>
                        <h2>Service Description</h2>
                        <p>
                            Upright Bites facilitates the ordering, pickup, and delivery of food and beverages from local restaurants. Depending on our relationship with the restaurant, Upright Bites may act as either:</p>
                        <ul>
                            <li>A Concierge Service, assisting you in placing orders with local restaurants and arranging for the pickup and delivery of those orders without acting as a traditional delivery service provider.
                            </li>
                            <li>A Traditional Delivery Service, directly providing delivery services from the restaurant to you.
                            </li>
                        </ul>
                        <h2>Product Listings</h2>
                        <p>
                            The food and beverage products listed on our platform are not advertised or promoted by Upright Bites. They are provided solely for the convenience of our services, allowing you to access and order from local restaurants. In cases where Upright Bites acts as a concierge service, listings are based on publicly available information, and we take reasonable steps to ensure accuracy.
                        </p>
                        <p>Upright Bites may list a restaurant's menus and offerings on our platform based on publicly available information. In the absence of an agreement specifying otherwise, there are no charges or fees imposed on the restaurant for being listed on our platform or for the delivery services provided. Additionally, in the absence of an agreement specifying otherwise, Upright Bites may add or remove restaurants listed on our platform at any time at our discretion. Restaurants that do not wish to be listed on our platform or want to modify their listings can contact us at <Link to={'mailto:help@uprightbites.com'}>help@uprightbites.com</Link> to request changes or removal. We will promptly remove or update listings as requested.</p>
                        <h2>Limited Authorization to Act on Your Behalf</h2>
                        <p>When we act as a concierge service, by placing an order through Upright Bites, you authorize us to act on your behalf to place and manage your order with the restaurant, including payment processing, order pickup, and delivery arrangement.</p>

                        <h2>Account Registration</h2>
                        <p>
                            To use certain features of the Services, you may need to register for an account. You agree
                            to provide accurate, current, and complete information during the registration process and
                            to update such information as needed. You are responsible for maintaining the
                            confidentiality of your account credentials and for all activities that occur under your
                            account.
                        </p>

                        <h2>Unauthorized use</h2>
                        <p>
                            You agree to use the Services in accordance with all applicable laws and regulations. You
                            will not use the Services for any illegal or unauthorized purpose. You will not interfere
                            with or disrupt the operation of the Services or the servers or networks connected to the
                            Services.
                        </p>

                        <h2>Order and Delivery</h2>
                        <p>
                            When you place an order through the Services, you agree to pay the total amount specified in
                            the order, including any applicable taxes and fees. Upright Bites will facilitate the
                            delivery of your order. Delivery times are estimates and may vary.
                        </p>

                        <h2>Payment</h2>
                        <p>
                            All payments for orders placed through the Services must be made through the payment methods
                            provided by Upright Bites. You authorize Upright Bites to charge your chosen payment method
                            for the total amount of your order. If your payment method fails, Upright Bites may cancel
                            your order.
                        </p>
                        <h2>Privacy Policy</h2>
                        <p>
                            Your use of the Services is also governed by our <Link to="/privacy">Privacy
                                Policy</Link>, which is hereby incorporated into these Terms by reference. Please review our
                            Privacy Policy to understand how we collect, use, and disclose information about you.
                        </p>

                        <h2>Cancellation and Refunds</h2>
                        <p>You may request a cancellation of your order by contacting us at <Link to={'mailto:help@uprightbites.com'}>help@uprightbites.com</Link>.
                            Refunds may be
                            issued in accordance with our Refund Policy, which is incorporated into these Terms by
                            reference.</p>

                        <h2>Refund Policy</h2>
                        <p>
                            If you are not satisfied with your order, you may request a refund. To request a refund,
                            please contact our customer support
                            team at <Link to={'mailto:help@uprightbites.com'}>help@uprightbites.com</Link> with your
                            order details and the reason for the refund
                            request.
                        </p>
                        <p>
                            Refunds will be processed based on the nature of the issue and in accordance with our sole
                            discretion. If approved, the refund will be issued to your original payment method within
                            7-10 business days. Please note that certain items or services may be non-refundable as
                            specified during the order process.
                        </p>
                        <p>
                            Upright Bites reserves the right to refuse refund requests if we determine that the request
                            is not in compliance with our Refund Policy or if there is evidence of abuse of our refund
                            process.
                        </p>

                        <h2>Intellectual Property</h2>
                        <p>
                            All content and materials on the Services, including text, graphics, logos, and software,
                            are the property of Upright Bites or its licensors and are protected by copyright,
                            trademark, and other intellectual property laws. You may not use any content or materials on
                            the Services without the prior written consent of Upright Bites.
                        </p>

                        <h2>Limitation of Liability</h2>
                        <p>
                            To the fullest extent permitted by law, Upright Bites shall not be liable for any indirect,
                            incidental, special, consequential, or punitive damages, or any loss of profits or revenues,
                            whether incurred directly or indirectly, or any loss of data, use, goodwill, or other
                            intangible losses, resulting from (i) your use or inability to use the Services; (ii) any
                            unauthorized access to or use of our servers and/or any personal information stored therein;
                            (iii) any interruption or cessation of transmission to or from the Services; or (iv) any
                            bugs, viruses, trojan horses, or the like that may be transmitted to or through our Services
                            by any third party.
                        </p>

                        <h2>Governing Law</h2>
                        <p>
                            These Terms and any disputes arising out of or related to these Terms or the Services will
                            be governed by and construed in accordance with the laws of the State of New York, without
                            regard to its conflict of law principles.
                        </p>

                        <h2>Changes to These Terms</h2>
                        <p>
                            Upright Bites reserves the right to modify these Terms at any time. We will notify you of
                            any changes by posting the new Terms on the Services. Your continued use of the Services
                            after the changes become effective constitutes your acceptance of the new Terms.
                        </p>

                        <h2>Contact Us</h2>
                        <p>
                            If you have any questions about these Terms, please contact us at <Link
                                to={'mailto:help@uprightbites.com'}>help@uprightbites.com</Link>.
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    );

}

export default TermsPage;

