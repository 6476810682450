import React, { useState, useEffect } from "react";
import {useNavigate, useParams, Link, useLocation} from "react-router-dom";
import {Container, Row, Col, FormGroup, Form, Button, ProgressBar, Table, Modal, Spinner, Alert} from "react-bootstrap";
import {Helmet} from "react-helmet";
import {getAuth, onAuthStateChanged, signInAnonymously} from "firebase/auth";
import app from "../firebaseConfig";
import closedModal from "../components/closedModal";
import ClosedModal from "../components/closedModal";
import ReactMarkdown from 'react-markdown'
import TiktokPixel from "tiktok-pixel";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test public API key.
const DirectDineStepsPage = ({operatingStatus}) => {

    const storedAddressString = localStorage.getItem('deliveryAddress');
    const [storedCoords, setStoredCoords] = useState(localStorage.getItem('deliveryCoordinates'));
    const deliveryInformation = JSON.parse(sessionStorage.getItem('deliveryInformation'));
    const [geoCoords, setGeoCoords] = useState('');
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [deliveryInstructions, setDeliveryInstructions] = useState('');
    const [deliveryError, setDeliveryError] = useState(false);
    const [validated, setValidated] = useState(false);
    const [formValidationError, setFormValidationError] = useState(false);
    const [restaurantData, setRestaurantData] = useState([]);
    const {restaurantId, directDineStep} = useParams();
    const [directDineRestSteps, setDirectDineRestSteps] = useState(0);
    const auth = getAuth(app);
    const [user, setUser] = useState(auth.currentUser || '');
    const location = useLocation();
    const currentPath = window.location.pathname;

    TiktokPixel.pageView(); // For tracking page view



    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (!user) {
                signInAnonymously(auth).then((credential) => {
                    setUser(credential.user);
                });
            }
            return () => unsubscribe();
        });
    }, [auth]);

    useEffect(() => {
        if (!deliveryInformation) {
            navigate(`/direct-dine/${restaurantId}/verify-address/`, { state: { error: 'Please re-enter your delivery information' } });
        }
    }, [deliveryInformation]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVICES_DOMAIN}/restaurant/directdine/${restaurantId}`);
                const data = await response.json();
                setRestaurantData(data);
                // count the number of directdine steps
                const directDineStepsLength = data.directDineSteps.length
                setDirectDineRestSteps(directDineStepsLength);
                console.log('Menu data fetched successfully!');
            } catch (error) {
                console.error('Error fetching menu:', error);
            }
        };
        fetchData();
        if((parseInt(directDineStep) - 2)  > directDineRestSteps){
            navigate(`/direct-dine/${restaurantId}/payment`);
        }
    }, []);





    return (
        <Container>
            <Helmet>
                <title>Upright Bites: Direct Dine Order</title>
            </Helmet>
            <ClosedModal operatingStatus={operatingStatus} />
            <Row className="d-flex align-items-center justify-content-center mt-2">
            <Col xl={4}>
                <h1>Direct Dine Order</h1>
            </Col>
            <Col xl={4}>
                <p className={'text-secondary mb-1'}>Your order from:</p>
                <div style={{cursor: 'pointer'}} onClick={() => navigate(`/menu/${restaurantId}`)}>
                    <h6 className={'mb-1 ps-2'}>{restaurantData.name}</h6>
                    <p className="text-secondary mb-4 ps-2">{restaurantData.address1}{restaurantData.address2 ? `, ${restaurantData.address2}` : ''}, {restaurantData.city} {restaurantData.state} {restaurantData.zipcode}</p>
                </div>
            </Col>
                <Col xl={4}>
                    <p>
                        <div className={'text-secondary mb-1'}>Order summary:</div>
                        <div className={'mb-1 ps-2'}>
                            <Table>
                                <tr>
                                    <td>Delivery fee</td>
                                    <td>$5.00</td>
                                </tr>
                                {user.isAnonymous &&
                                    <tr>
                                        <td><i className="bi bi-tag-fill"></i>First order delivered free</td>
                                        <td>-$5.00</td>
                                    </tr>
                                }
                                <tr className={'fw-semibold'}>
                                    <td>Total</td>
                                    <td>{user.isAnonymous ? '$0.00' : '$5.00'}</td>
                                </tr>
                            </Table>
                        </div>
                    </p>
                </Col>
        </Row>
            {!restaurantData.directDineSteps  ? (
                <Row className="d-flex justify-content-center">
                    <Col className="text-center">
                        <Spinner animation="border" role="status">
                           <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
            ) :
                <>
                    <Row className="align-items-center bg-light mb-3" style={{borderRadius: '15px', overflow: 'hidden'}}>
                        <Col
                            className={"text-center d-inline-block pt-3 pb-3"}><Link to={`/direct-dine/${restaurantId}/verify-address/`} style={{textDecoration: "none", color: "inherit"}}>Verify
                            Address</Link></Col>
                        {restaurantData.directDineSteps && restaurantData.directDineSteps.map((step, index) => (
                            <Col
                                className={index === parseInt(directDineStep) - 2 ? "text-center text-white bg-secondary fw-bold d-inline-block pt-3 pb-3 d-none d-sm-block" : "text-center text-black d-inline-block pt-3 pb-3 d-none d-sm-block"}>
                                {index <= parseInt(directDineStep) - 3 ? (
                                    <Link to={`/direct-dine/${restaurantId}/step/${index + 2 }`} style={{textDecoration: "none", color: "inherit"}}>{step.title}</Link>
                                ) : (
                                    step.title
                                )}
                            </Col>
                        ))}
                        <Col className={"text-center text-white bg-secondary fw-bold d-inline-block pt-3 pb-3 d-block d-sm-none"}>Order food</Col>
                        <Col className={"text-center text-black d-inline-block pt-3 pb-3"}>Payment</Col>
                    </Row>
                    <Container className="bg-light pt-4 pb-4">
                        <Row className="d-flex justify-content-center align-items-center">
                            <Col lg={8} xl={6}>
                                <p>
                                    <ReactMarkdown
                                        components={{
                                            a: ({node, ...props}) => <a {...props} target="_blank"
                                                                        rel="noopener noreferrer nofollow"/>
                                        }}>
                                    {restaurantData.directDineSteps[parseInt(directDineStep) - 2].instructions}
                                    </ReactMarkdown>
                                </p>
                                {restaurantData.directDineSteps[parseInt(directDineStep) - 2].imgurl &&
                                    <p className={'text-center'}>
                                    <img src={restaurantData.directDineSteps[parseInt(directDineStep) - 2].imgurl}
                                         alt={restaurantData.directDineSteps[parseInt(directDineStep) - 2].title}
                                         className={"img-fluid"}/>
                                </p>
                                }
                                <div className="d-grid gap-2 mb-3">
                                    <Button className={'flex-fill'} variant={'primary'} onClick={() =>
                                        parseInt(directDineStep) - 1 == directDineRestSteps ? (
                                            navigate(`/direct-dine/${restaurantId}/payment`, {state: {restaurantData}})
                                        ) : (
                                            navigate(`/direct-dine/${restaurantId}/step/${parseInt(directDineStep) + 1}`))
                                    }>Next</Button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </>
            }
        </Container>
    )
};

export default DirectDineStepsPage;