import React, { useState, useEffect, useRef } from "react";
import {loadStripe } from '@stripe/stripe-js';
import {Navigate, useLocation, Link, useNavigate, useParams} from "react-router-dom";
import UprightNav from "../components/navbar";
import {Container, Row, Col, Table, Card, ListGroup, InputGroup, Form, Button, Alert, Tooltip, OverlayTrigger} from "react-bootstrap";
import {Helmet} from "react-helmet";
import {QuestionCircle, TagFill} from "react-bootstrap-icons";
import {Elements, useStripe, useElements, PaymentElement} from "@stripe/react-stripe-js";
import PaymentForm from "../components/paymentform";
import {getAuth, onAuthStateChanged, signInAnonymously} from "firebase/auth";
import app from "../firebaseConfig";
import closedModal from "../components/closedModal";
import ClosedModal from "../components/closedModal";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test public API key.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const DirectDinePayment = ({operatingStatus}) => {
    const [clientSecret, setClientSecret] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const [restaurantData, setRestaurantData] = useState([]);
    const {restaurantId} = useParams();
    const deliveryInformation = JSON.parse(sessionStorage.getItem('deliveryInformation')) || '';
    const [tip, setTip] = useState(0.00);
    const [discounts, setDiscounts] = useState(0.00);
    const deliveryFee = 5.00;
    const orderTotal = (deliveryFee + tip - discounts);
    const [stripeError, setStripeError] = useState(false);
    const paymentError = location.state?.paymentError || false;
    const [returnURL, setReturnURL] = useState('');
    const [IsTipValid, setIsTipValid] = useState(true);
    const initialRender = useRef(true);
    const [paymentIntentId, setPaymentIntentId] = useState('');
    const auth = getAuth(app);
    const [user, setUser] = useState(auth.currentUser || '');
    const currentPath = window.location.pathname;
    const [firstOrder, setFirstOrder] = useState(false);
    const [orderNo, setOrderNo] = useState('')

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (!user) {
                signInAnonymously(auth).then((credential) => {
                    setUser(credential.user);
                });
            }
            return () => unsubscribe();
        });
    }, [auth]);

    useEffect(() => {
        console.log('deliveryInformation ' &deliveryInformation)
        if (!deliveryInformation) {
            navigate(`/direct-dine/${restaurantId}/verify-address/`, { state: { error: 'Please re-enter your delivery information' } });
            return;
        }
        let ignore = false;
        fetchData();
            if(restaurantData && deliveryInformation && !clientSecret && user) {
                const reqBody = {
                    'restaurantId': restaurantId,
                    'deliveryInformation': deliveryInformation,
                    'userId': user.uid,
                    'email': deliveryInformation.email
                }
                const response = fetch(`${process.env.REACT_APP_SERVICES_DOMAIN}/create-direct-dine-payment-intent`, {
                    method: "POST",
                    body: JSON.stringify(reqBody),
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                    .then(response => response.json())
                    .then(data => {
                        if (!ignore) {
                            setClientSecret(data.client_secret)
                            setPaymentIntentId(data.id)
                            setOrderNo(data.orderNo)
                            data.firstOrder && setFirstOrder(data.firstOrder)
                            data.firstOrder && setDiscounts(5.00)
                        }
                    })
            }
        return () => {
            ignore = true
            }
    }, [restaurantId, user]);

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else {
        updatePaymentIntent();
        console.log('tip updated for ', paymentIntentId);
    }
    }, [tip]);




    async function fetchData (){
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVICES_DOMAIN}/restaurant/directdine/${restaurantId}`);
            const data = await response.json();
            setRestaurantData(data);
            console.log('Menu data fetched successfully!');
        } catch (error) {
            console.error('Error fetching menu:', error);
        }
    };

    async function updatePaymentIntent () {
        try {
            const reqBody = {
                'id': paymentIntentId,
                'tip': tip,
                'orderTotal': orderTotal
            }
            console.log(reqBody);
            const response = await fetch(`${process.env.REACT_APP_SERVICES_DOMAIN}/update-direct-dine-payment-intent`, {
                method: "POST",
                body: JSON.stringify(reqBody),
                headers: {
                    "Content-Type": "application/json",
                },
            })
        }
        catch {
            setStripeError(true);
        }
    }

    const options = {
        // passing the client secret obtained in step 3
        clientSecret: clientSecret
    };
    
    async function updateTip(event) {
        const value = event.target.value;
        const pattern = /^\$?\d{1,6}(\.\d{1,2})?$/; // Modified pattern
        if (pattern.test(value) || value === '') {
            let tipValue = value === '' ? 0 : parseFloat(value.replace('$', ''));
            setTip(tipValue);
            setIsTipValid(true);
            } else {
            setIsTipValid(false);
        }
    }

    const renderTooltip = (props) => (
        <Tooltip id="tip-tooltip" {...props} >
            <p className={'text-start'}>Our delivery team members earn at least $25/hr with health benefits and paid time off.</p>
            <p className={'text-start'}>We ensure our delivery team members' basic needs are met, so there is no obligation to tip.</p>
            <p className={'text-start'}>If you'd like show extra appreciation to the person delivering your order, they will receive 100% of the amount you specify.</p>
        </Tooltip>
    );

    // async function handleZeroDollarOrder() {
    //     const idToken = await user.getIdToken();
    //     console.log(idToken);
    //     const retrieveData = async (idToken) => {
    //         const response = await fetch(`${process.env.REACT_APP_SERVICES_DOMAIN}/direct-dine-first-order/${orderNo}`, {
    //             headers: {
    //                 'authorization': idToken,
    //             }
    //         });
    //         if (response.status === 200) {
    //             navigate(`/direct-dine/${restaurantId}/confirmation?order_number=${orderNo}`);
    //             return;
    //         }
    //     }
    //     await retrieveData(idToken)
    // }

    async function handleZeroDollarOrder() {
        const idToken = await user.getIdToken();
        const retrieveData = async () => {
            console.log(idToken)
            const response = await fetch(`${process.env.REACT_APP_SERVICES_DOMAIN}/direct-dine-first-order/${orderNo}`, {
                type: 'GET',
                headers: {
                    'authorization': idToken,
                }
            });
            return response.status;
        }
        await retrieveData()
            .then(data => {
                if(data === 200) {
                    navigate(`/direct-dine/${restaurantId}/confirmation?order_number=${orderNo}`, { state: { zeroDollarOrder: true } });
                    return;
                }
            })
            .catch(error => {
                console.error('Error:', error);
                setStripeError(true);
            });
    }



    return (
        <Container>
            <Helmet>
                <title>Direct Dine : Upright Bites</title>
            </Helmet>
            <ClosedModal operatingStatus={operatingStatus} />
            <Row className="d-flex align-items-center justify-content-center mt-2">
                <Col xl={4}>
                    {paymentError === true &&
                        <Alert variant={'danger'}>An error has occurred. Please try again.</Alert>
                    }
                    <h1>Direct Dine Order</h1>
                </Col>
                <Col xl={4}>
                    <p className={'text-secondary mb-1'}>Your order from:</p>
                    <div style={{cursor: 'pointer'}} onClick={() => navigate(`/menu/${restaurantData.restaurantId}`)}>
                        <h6 className={'mb-1 ps-2'}>{restaurantData.name}</h6>
                        <p className="text-secondary mb-4 ps-2">{restaurantData.address1}{restaurantData.address2 ? `, ${restaurantData.address2}` : ''}, {restaurantData.city} {restaurantData.state} {restaurantData.zipcode}</p>
                    </div>
                </Col>
                <Row className="align-items-center bg-light mb-2" style={{borderRadius: '15px', padding: '0'}}>
                    <Col
                        className={"text-center d-inline-block pt-3 pb-3"}><Link to={`/direct-dine/${restaurantId}/verify-address/`} style={{textDecoration: "none", color: "inherit"}}>Verify
                        Address</Link></Col>
                    {restaurantData.directDineSteps && restaurantData.directDineSteps.map((step, index) => (
                        <Col
                            className={"text-center text-black d-inline-block pt-3 pb-3 d-none d-sm-block"}><Link to={`/direct-dine/${restaurantId}/step/${index + 2 }`} style={{textDecoration: "none", color: "inherit"}}>{step.title}</Link></Col>
                    ))}
                    <Col className={"text-center text-black d-inline-block pt-3 pb-3 d-block d-sm-none"}><Link to={`/direct-dine/${restaurantId}/step/2`} style={{textDecoration: "none", color: "inherit"}}>Order food</Link></Col>
                    <Col className={"text-center text-white bg-secondary fw-bold d-inline-block pt-3 pb-3"} style={{borderBottomRightRadius: '15px', borderTopRightRadius: '15px', padding: '0.75rem'}} >Payment</Col>
                </Row>
            </Row>
            <Row>
                <Col xl={6}>
                    <Card style={{ height: '100%' }}>
                        <Card.Header>Order from</Card.Header>
                        <Card.Body>
                            <p>
                                <h6 className={'mb-1 ps-2'}>{restaurantData.name}</h6>
                                <p className="text-secondary mb-4 ps-2">{restaurantData.address1}{restaurantData.address2 ? `<br /> ${restaurantData.address2}` : ''} <br />{restaurantData.city} {restaurantData.state} {restaurantData.zipcode}</p>
                            </p>
                    </Card.Body>
                </Card>
            </Col>
            <Col xl={6}>
                <Card style={{height: '100%'}}>
                    <Card.Header>Deliver to</Card.Header>
                    <Card.Body>
                        <p>{deliveryInformation.deliveryFirstName} {deliveryInformation.deliveryLastName}<br/>
                            {deliveryInformation.deliveryAddress1}<br />
                                {deliveryInformation.deliveryAddress2 && deliveryInformation.deliveryAddress2} {deliveryInformation.deliveryAddress2 && <br />}
                                {deliveryInformation.deliveryCity}, {deliveryInformation.deliveryState} {deliveryInformation.deliveryZip} <br />
                                {deliveryInformation.deliveryTel}
                        </p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className={'justify-content-center mt-3 mb-3'}>
                <Col xl={6}>
                    {/*<Card className={'h-100'}>*/}
                    {/*    <Card.Header>Order details</Card.Header>*/}
                        <ListGroup variant="flush">
                            <Row className={'mt-2'}>
                                <Col className={'ms-2'}>
                                    <p>Delivery Fee
                                        <div className={'text-secondary small'}>Always $5!</div>
                                    </p>
                                </Col>
                                <Col className={'d-inline-block'}>
                                    <p>$5.00</p>
                                </Col>

                            </Row>
                            <Row className={'mt-2'}>
                                <Col className={'ms-2'}>
                                    <p>Tip
                                        <div className={'text-secondary small'}>Tipping is optional, but appreciated <OverlayTrigger
                                            placement="right"
                                            overlay={renderTooltip}

                                        ><Button variant={'link'} className={'p-0 mb-1'}><QuestionCircle /></Button>
                                        </OverlayTrigger>
                                        </div>
                                    </p>
                                </Col>
                                <Col className={'d-inline-block'}>
                                    <InputGroup controlId="tipField">
                                        <InputGroup.Text>$</InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            placeholder="0.00"
                                            onChange={event => updateTip(event)}
                                            isInvalid={IsTipValid === false}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a number with up to 2 decimal places.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                            </Row>
                            {firstOrder &&
                            <>
                            <Row className={'mt-2'}>
                                <Col className={'ms-2'}>
                                    <p><TagFill /> First order delivered free
                                        <div className={'text-secondary small'}>$5.00 off</div>
                                    </p>
                                </Col>
                                <Col className={'d-inline-block'}>
                                    <p className={'text-secondary'} >-$5.00</p>
                                </Col>
                            </Row>
                            </>
                            }
                            <Row>
                                <Col className={'fw-bold'}>Total
                                </Col>
                                <Col className={'fw-bold d-inline-block'}>
                                    <p>${orderTotal.toFixed(2)}</p>
                                </Col>

                            </Row>
                        </ListGroup>
                    {/*</Card>*/}
                </Col>
                <Col xl={6}>
                    {clientSecret && (!firstOrder || (firstOrder && tip > 0)) &&
                        <Elements stripe={stripePromise} options={options}>
                            <PaymentForm restaurantId={restaurantId} />
                        </Elements>
                    }
                    {stripeError === true &&
                        <Alert variant={'danger'}>An error has occurred. Please try again.</Alert>
                    }
                    {firstOrder && tip === 0 &&
                        <>
                            <Card>
                                <Card.Body>No payment due.</Card.Body>
                            </Card>
                        <div className="d-grid gap-2 mt-3">
                            <Button variant={"primary"} onClick={handleZeroDollarOrder}>Complete Order</Button>
                        </div>
                        </>
                    }
                </Col>
            </Row>
        </Container>
    )
}
export default DirectDinePayment;