import React, { useState, useEffect } from 'react';
import {Container, Row, Col, Card, Button, ListGroup, Alert} from 'react-bootstrap';
import { useShoppingCart } from 'use-shopping-cart';
import {useNavigate, Link, useLocation, useParams} from "react-router-dom";
import {CheckCircle} from 'react-bootstrap-icons';
import {Helmet} from "react-helmet";
import {getAuth, onAuthStateChanged, signInAnonymously} from "firebase/auth";
import app from "../firebaseConfig";


function OrderConfirmation() {
    const {clearCart} = useShoppingCart();
    const navigate = useNavigate()
    const location = useLocation();
    const sessionParams = new URLSearchParams(location.search);
    const sessionId = sessionParams.get('session_Id');
    let [sessionData, setSessionData] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    let auth = getAuth(app);
    const [user, setUser] = useState(auth.currentUser);
    const currentPath = window.location.pathname;
    const [authError, setAuthError] = useState(false);
    let idToken

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(auth.currentUser);
        });
        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [auth]);


    useEffect(() => {
        const unsubscribe = async function () {
            console.log('user is', user, 'session is', sessionId)
            if (user && sessionId) {
                idToken = await user.getIdToken();
                console.log(idToken);
        clearCart();
        const retrieveData = async () => {
                const response = await fetch(`${process.env.REACT_APP_SERVICES_DOMAIN}/session-status/${sessionId}`, {
                    headers: {
                        'Authorization': idToken,
                    }
                });
                if (response.status >= 400 && response.status < 500) {
                    setAuthError(true);
                    setIsLoading(false);
                    return;
                }
                const data = await response.json();
                return data;
            } 
            retrieveData(idToken)
            .then(data => {
                setSessionData(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error:', error);
                setIsLoading(false); // Add this line
            });
        }};
            unsubscribe();
        }, [navigate, currentPath, user, auth]);



    if (isLoading) {
        return <div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div>;
    }
    if (authError) {
        window.umami.track(`Auth error`);
        return <Container><Alert variant={'danger'} className={'mt-3'}>Not authorized</Alert></Container>
    }
    if (sessionData.status === 'open') {
        navigate("/checkout/payment", {state: {error: true } })}
    if (sessionData.status === 'complete') {
        return (
            <Container>
                <Helmet>
                    <title>Order confirmed : Upright Bites</title>
                </Helmet>
                <Row className={"mt-2"}>
                    <Col xs={2} md={1}><CheckCircle style={{ height: '100%', width: '100%' }}/></Col>
                    <Col>
                        <h1>Thank you!</h1>
                        <p className={'fw-bold'}>Order # {sessionData.client_reference_id}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>Your order has been placed and is being prepared. You will receive a confirmation email
                            shortly.</p>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <Card style={{ height: '100%' }}>
                            <Card.Header>Order from</Card.Header>
                                <Card.Body>
                                <p><div className={'fw-bold'}>{sessionData.restaurantDetails.name}</div>
                                    {sessionData.restaurantDetails.address1} <br />
                                    {sessionData.restaurantDetails.address2 && sessionData.restaurantDetails.address2} {sessionData.restaurantDetails.address2 && <br />}
                                    {sessionData.restaurantDetails.city}, {sessionData.restaurantDetails.state} {sessionData.restaurantDetails.zipcode}</p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6}>
                        <Card style={{ height: '100%' }}>
                            <Card.Header>Deliver to</Card.Header>
                            <Card.Body>
                                <p>{sessionData.metadata.deliveryFirstName} {sessionData.metadata.deliveryLastName}<br />
                                {sessionData.metadata.deliveryAddress1}<br />
                                {sessionData.metadata.deliveryAddress2 && sessionData.metadata.deliveryAddress2} {sessionData.metadata.deliveryAddress2 && <br />}
                                {sessionData.metadata.deliveryCity}, {sessionData.metadata.deliveryState} {sessionData.metadata.deliveryZip}</p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className={'mt-3'}>
                    <Col>
                <Card>
                    <Card.Header>Order details</Card.Header>
                <Row className={'ps-2'}>
                    <Col>
                        <p className={'fw-bold'}>Item</p>
                    </Col>
                    <Col>
                        <p className={'fw-bold'}>Quantity</p>
                    </Col>
                    <Col>
                        <p className={'fw-bold'}>Price</p>
                    </Col>
                </Row>
                {Object.keys(sessionData.lineItems).map((itemId) => {
                                                const item = sessionData.lineItems[itemId];
                                                return (
                                                    <Row className={'ps-2'} key={itemId}>
                                                        <Col>
                                                            <p>{item.description}</p>
                                                        </Col>
                                                        <Col>
                                                            <p>{item.quantity}</p>
                                                        </Col>
                                                        <Col>
                                                            <p>{'$'}{(item.amount_total * 0.01).toFixed(2)}</p>
                                                        </Col>
                                                    </Row>
                                                )
                                            }
                                            )
                        }
                    <ListGroup variant="flush">
                    <Row className={'mt-2'}>
                        <Col>
                        </Col>
                        <Col>
                            <p className={'fw-bold text-end'}>Total</p>

                        </Col>
                        <Col>
                            <p className={'fw-bold'}>{'$'}{(sessionData.amount_total * 0.01).toFixed(2)}</p>
                        </Col>
                    </Row>
                    </ListGroup>
                </Card>
                    </Col>
                </Row>
                <Row className={'mt-5'}>
                    <Col className="text-center">
                        <Button variant={'outline-secondary'} as={Link} to={'/order'} data-umami-event={"Hungry for more button"}>Hungry for more?</Button>
                    </Col>
                </Row>
            </Container>

        )
    }}


export default OrderConfirmation;
