import {Button, Modal} from 'react-bootstrap';
import React from "react";
function ClosedModal( {operatingStatus} ) {

    return (
            <Modal
                show={operatingStatus.status === 'closed'}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        We are currently closed.
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {operatingStatus.reason === 'override' ? (operatingStatus.message || "Please try again later.") : null}
                        {operatingStatus.reason === 'hours' && `Our normal operating hours are ${operatingStatus.open.formatted} to ${operatingStatus.close.formatted} Monday - Sunday. Please come back during our operating hours to place an order.`}
                    </p>
                </Modal.Body>
            </Modal>
    )
}

export default ClosedModal ;