import React from 'react';
import {
    Container,
    Row,
    Col,
} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

const PrivacyPage = () => {

    return (
        <Container>
            <Helmet>
                <title>Privacy Policy : Upright Bites</title>
            </Helmet>
            <Row className="d-flex align-items-center justify-content-center mt-2">
                <Col>
                    <div>
                        <h1>Upright Bites Privacy Policy</h1>
                        <p>Effective Date: July 10, 2024</p>

                        <h2>Introduction</h2>
                        <p>
                            Upright Bites, Inc. ("we," "us," or "our") values your privacy. This Privacy Policy outlines the types of information we collect, how we use it, and the steps we take to protect your information.
                        </p>

                        <h2>Information We Collect</h2>
                        <ul>
                            <li>
                                <strong>Personal Information:</strong> When you register for an account, place an order, or contact us, we may collect personal information such as your name, email address, phone number, delivery address, and payment information.
                            </li>
                            <li>
                                <strong>Usage Information:</strong> We collect information about how you interact with our Services, including your IP address, browser type, operating system, pages viewed, and the dates/times of your visits.
                            </li>
                            <li>
                                <strong>Cookies and Tracking Technologies:</strong> We use cookies and similar tracking technologies to enhance your experience, gather information about site usage, and understand your preferences.
                            </li>
                        </ul>

                        <h2>How We Use Your Information</h2>
                        <ul>
                            <li>
                                <strong>To Provide and Improve Our Services:</strong> We use your information to process orders, facilitate deliveries, and improve the overall user experience.
                            </li>
                            <li>
                                <strong>To Communicate with You:</strong> We may use your contact information to send you updates, promotional materials, and other information related to your use of our Services.
                            </li>
                            <li>
                                <strong>To Enhance Security:</strong> We use the information to monitor and ensure the security of our Services.
                            </li>
                            <li>
                                <strong>For Analytics and Research:</strong> We analyze usage data to understand user behavior and improve our Services.
                            </li>
                        </ul>

                        <h2>Sharing Your Information</h2>
                        <p>We may share your information with:</p>
                        <ul>
                            <li>
                                <strong>Service Providers:</strong> Third-party service providers who assist us with payment processing, delivery services, and other functions necessary to operate our business.
                            </li>
                            <li>
                                <strong>Business Partners:</strong> Partners who collaborate with us on promotions, offers, or other initiatives.
                            </li>
                            <li>
                                <strong>Legal Requirements:</strong> When required by law or to protect our rights, we may disclose your information to government authorities or other third parties.
                            </li>
                        </ul>

                        <h2>Data Security</h2>
                        <p>
                            We implement various security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure.
                        </p>

                        <h2>Your Choices</h2>
                        <ul>
                            <li>
                                <strong>Update Account Information:</strong> You can update your account information at
                                any time by logging into your account.
                            </li>
                            <li>
                                <strong>Account Deletion:</strong> You can request deletion of your account by contacting us at <Link to={'mailto:help@uprightbites.com'}>help@uprightbites.com</Link>.
                            </li>
                            <li>
                                <strong>Marketing Communications:</strong> You can opt-out of receiving promotional
                                emails from us by following the unsubscribe instructions included in these emails.
                            </li>
                        </ul>

                        <h2>Children's Privacy</h2>
                        <p>
                            Our Services are not intended for individuals under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that we have inadvertently received such information, we will delete it.
                        </p>

                        <h2>Changes to This Privacy Policy</h2>
                        <p>
                            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our Services. Your continued use of the Services after any changes indicates your acceptance of the updated Privacy Policy.
                        </p>

                        <h2>Contact Us</h2>
                        <p>
                            If you have any questions or concerns about this Privacy Policy, please contact us at <Link to={'mailto:help@uprightbites.com'}>help@uprightbites.com</Link>.
                        </p>

                        <p>
                            By using our Services, you agree to the terms of this Privacy Policy. If you do not agree with this Privacy Policy, please do not use our Services.
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    );

}

export default PrivacyPage;

