import React, {useEffect, useState} from 'react';
import {
    Container,
    Row,
    Col,
    Form,
    Button, Alert
} from "react-bootstrap";
import {Helmet} from "react-helmet";
import {getAuth, onAuthStateChanged, sendPasswordResetEmail} from "firebase/auth";
import app from "../firebaseConfig";
import { useNavigate } from "react-router-dom";
import {EnvelopeCheck} from "react-bootstrap-icons";


const ForgotPassword = () => {

    const [validated, setValidated] = useState(false);
    const [formSubmissionError, setFormSubmissionError] = useState(false)
    const [formSubmissionSuccess, setFormSubmissionSuccess] = useState(false)
    const auth = getAuth();
    let user
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        setValidated(true);
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            //set email and password field values to variables
            const email = form.email.value;
            sendPasswordResetEmail (auth, email )
                .then(() => {
                    // Signed up
                    setFormSubmissionSuccess(true)
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    setFormSubmissionError(true)
                })
    }
}

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                navigate('/account')
            }
            // Cleanup subscription on unmount
            return () => unsubscribe();
        }, [auth, user]);
    });




    return (
        <Container>
            <Helmet>
                <title>Forgot Password : Upright Bites</title>
            </Helmet>
            <Row className="d-flex align-items-center justify-content-center mt-2">
                <Col>
                    <h1>Forgot Password</h1>
                    {formSubmissionError &&
                    <Alert variant={"danger"}>An error occurred, please try again.</Alert>
                        }
                    {formSubmissionSuccess &&
                    <Alert variant={"success"}><EnvelopeCheck></EnvelopeCheck> An email has been sent to reset your password.</Alert>
                    }
                    <p>
                        Enter your email address below and we'll send you a link to reset your password.
                    </p>
                </Col>

            </Row>
            <Row>
                <Col lg={6}>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="formBasicEmail" >
                                <Form.Label>Email address</Form.Label>
                                <Form.Control required type="email" placeholder="Enter email" name={"email"}/>
                                <Form.Control.Feedback type="invalid">
                                    Please enter a valid email address.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Button className={"mb-3"} variant="primary" type="submit">
                                Submit
                            </Button>
                        </Form>
                </Col>
            </Row>
        </Container>
);

}

export default ForgotPassword;

