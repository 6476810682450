import React, {useState, useRef, useEffect} from 'react';
import {Container, Row, Col, Form, Button, InputGroup} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/styles.css';
import '../css/home.css';
import {useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
import UprightNav from "../components/navbar"
import { AddressAutofill } from '@mapbox/search-js-react';
import pizzaIcon from '../assets/img/icons8-pizza-100.png';
import saladIcon from '../assets/img/icons8-salad-100.png';
import sushiIcon from '../assets/img/icons8-sushi-100.png';
import chineseNoodleIcon from '../assets/img/icons8-chinese-noodle-100.png';
import moreIcon from '../assets/img/icons8-more-100.png';
import pancakeIcon from '../assets/img/icons8-pancake-stack-100.png';
import hamburgerIcon from '../assets/img/icons8-hamburger-100.png';
import tacoIcon from '../assets/img/icons8-taco-100.png';
import cookieIcon from '../assets/img/icons8-cookie-100.png';
import {CartFill, GeoAltFill, TagFill} from "react-bootstrap-icons";
import TiktokPixel from "tiktok-pixel";
import freeDeliveryIcon from '../assets/img/free-delivery-icon.png';
import noHiddenFeesIcon from '../assets/img/no-hidden-fees-icon.png';
import noSubscriptionIcon from '../assets/img/no-subscription-icon.png';

function Home(effect, deps) {
    const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
    const navigate = useNavigate();
    const deliveryAddress = localStorage.getItem('deliveryAddress');
    const deliveryCoordinates = localStorage.getItem('deliveryCoordinates');
    const [manualInputValue, setManualInputValue] = useState('');
    const [selectedCuisine, setSelectedCuisine] = useState([]);
    const elementRefs = useRef([]);

    useEffect(() => {
        const trackedElements = new Set();
    
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting && window.umami) {
                    const umamiName = entry.target.dataset.umamiName;
                    if (!trackedElements.has(umamiName)) {
                        trackedElements.add(umamiName);
                        window.umami.track(umamiName);
                    }
                }
            });
        });
    
        elementRefs.current.forEach(ref => {
            if (ref) observer.observe(ref);
        });
    
        return () => {
            elementRefs.current.forEach(ref => {
                if (ref) observer.unobserve(ref);
            });
        };
    }, []);


    TiktokPixel.pageView(); // For tracking page view


    const handleFoodIconClick = async (cusine) => {
        navigate('/order', {state: {
                selectedCuisine: cusine
            }})
    }

    const handleSearchClick = (e) => {
        e.preventDefault();
        localStorage.setItem('deliveryAddress', manualInputValue);
        navigate('/order');
    };

    const handleGeoButtonClick = () => {
            localStorage.removeItem('deliveryAddress');
            localStorage.removeItem('deliveryCoordinates')
            navigate('/order');
        };

    const handleManualAddressInput = (e) => {
        setManualInputValue(e);
    };

    const handleSelect = (data) => {
        const selectedAddress = data.features[0].properties.full_address
        // retrieve address from suggestionResults
        localStorage.setItem('deliveryAddress', selectedAddress)
        const selectedCoords = data.features[0].geometry.coordinates
        localStorage.setItem('deliveryCoordinates',JSON.stringify(selectedCoords))
        navigate('/order')
    };

    return (
        <Container>
            <Helmet>
                <title>Upright Bites</title>
                <meta name="description"
                      content="Delivery done right. Free delivery on all orders."/>
            </Helmet>
            <Container className={"masthead"}>
                <Container className={"cta"}>
                    <h1>Delivery done right</h1>
                </Container>
                <Container className={"search-form justify-content-center"}>
                    <Form id="deliveryAddressForm" onSubmit={handleSearchClick}>
                        <AddressAutofill
                            accessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
                            onRetrieve={(data) => handleSelect(data)}
                        >
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <InputGroup>
                                    <Form.Control
                                        type="text"
                                        size="lg"
                                        id="delivery-address"
                                        value={manualInputValue}
                                        onChange={(e) => (setManualInputValue(e.target.value))}
                                        placeholder="Delivery Address"
                                        data-umami-event={"delivery address search field"}
                                    /> <Button className={"uselocationbutton"} variant={"outline-secondary"}
                                               onClick={handleGeoButtonClick} alt={"Use current location"} data-umami-event={"Geolocation button"}

                                               ><GeoAltFill
                                    size={20}/></Button>
                                </InputGroup>
                            </div>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingTop: '1rem'
                            }}>
                                <Button type="submit" className={"submit-button"} size={"lg"} data-umami-event={"search button"}

>Search</Button>
                            </div>
                        </AddressAutofill>
                    </Form>
                </Container>
            </Container>
            <div className={'pb-2 pt-0'}
                 style={{background: '#fbfbf1'}}>
                <Row className={'justify-content-center align-items-center'}>
                    <Col sm={1} md={'auto'} className={'text-center'}>
                        <h1><TagFill className={'text-primary'}/></h1>
                    </Col>
                    <Col sm={11} md={'auto'}>
                        <Row className={'text-center'}>
                            <h1 className={'mt-2 mb-0'}>40% off your first order</h1>
                        </Row>
                        <Row className={'text-center'}>
                            <div className={'text-secondary'}><small>Maximum discount $10. No minimum order size required.<br/>
                                {/* <div
                                    className="w-100 d-sm-none"></div> This line acts as a line break on xs screens */}
                                Discount is applied automatically at checkout.
                                </small></div>
                        </Row>
                    </Col>
                </Row>
            </div>
            <Container className="d-none d-sm-block">
                <Row className="mt-3  justify-content-center">
                    <Col className="justify-content-center">
                        <img src={pizzaIcon} height={75} alt="Pizza icon" data-cuisine='Pizza'
                             style={{cursor: 'pointer'}}
                             onClick={(e) => handleFoodIconClick(e.target.dataset.cuisine)}
                             data-umami-event={"pizza icon"} />
                    </Col>
                    <Col className="justify-content-center">
                        <img src={saladIcon} height={75} alt="Salad icon" data-cuisine='Salad'
                             style={{cursor: 'pointer'}}
                             onClick={(e) => handleFoodIconClick(e.target.dataset.cuisine)}
                             data-umami-event={"salad icon"} />
                    </Col>
                    <Col className="justify-content-center">
                        <img src={sushiIcon} height={75} alt="Sushi icon" data-cuisine='Sushi'
                             style={{cursor: 'pointer'}}
                             onClick={(e) => handleFoodIconClick(e.target.dataset.cuisine)}
                             data-umami-event={"sushi icon"}/>
                    </Col>
                    <Col className="d-none d-lg-block justify-content-center">
                        <img src={chineseNoodleIcon} height={75} alt="Chinese noodle icon" data-cuisine='Chinese'
                             style={{cursor: 'pointer'}}
                             onClick={(e) => handleFoodIconClick(e.target.dataset.cuisine)}
                             data-umami-event={"chinese noodle icon"}/>
                    </Col>
                    <Col className="d-none d-lg-block justify-content-center">
                        <img src={cookieIcon} height={75} alt="Cookie icon" data-cuisine='Dessert'
                             style={{cursor: 'pointer'}}
                             onClick={(e) => handleFoodIconClick(e.target.dataset.cuisine)}
                             data-umami-event={"cookie icon"} />
                    </Col>
                    <Col className="d-none d-lg-block justify-content-center">
                        <img src={hamburgerIcon} height={75} alt="Hamburger icon" data-cuisine='American'
                             style={{cursor: 'pointer'}}
                             onClick={(e) => handleFoodIconClick(e.target.dataset.cuisine)}
                             data-umami-event={"hamburger icon"}/>
                    </Col>
                    <Col className="d-none d-xl-block justify-content-center">
                        <img src={pancakeIcon} height={75} alt="Pancake icon" data-cuisine='Breakfast'
                             style={{cursor: 'pointer'}}
                             onClick={(e) => handleFoodIconClick(e.target.dataset.cuisine)}
                             data-umami-event={"pancake icon"}/>
                    </Col>
                    <Col className="d-none d-xl-block justify-content-center">
                        <img src={tacoIcon} height={75} alt="Taco icon" data-cuisine='Tacos' style={{cursor: 'pointer'}}
                             onClick={(e) => handleFoodIconClick(e.target.dataset.cuisine)}
                             data-umami-event={"taco icon"}/>
                    </Col>
                    <Col className="justify-content-center">
                        <img src={moreIcon} height={75} alt="More icon" style={{cursor: 'pointer'}}
                             onClick={(e) => navigate('/order')}
                             data-umami-event={"more icon"}/>
                    </Col>
                </Row>
            </Container>
            <Container className="bg-light pt-3 pb-3">
                <Row>
                    <Col lg={4} ref={el => elementRefs.current[0] = el} data-umami-name={"$5 delivery fee value prop view"} > 
                    <Row className="d-flex flex-column align-items-center text-center">
                    <img src={freeDeliveryIcon} style={{ height: "100px", width: "auto" }} alt="Free Delivery on Every Order" />
                                                <h3 className={'mt-2'}>Free delivery on every order</h3>
                        </Row>
                    </Col>
                    <Col lg={4} ref={el => elementRefs.current[1] = el} data-umami-name={"Safe and reliable deliveries value prop view"}>
                    <Row className="d-flex flex-column align-items-center text-center">
                        <img src={noHiddenFeesIcon} style={{ height: "100px", width: "auto" }} alt="No hidden fees" />
                            <h3 className={'mt-2'}>No hidden fees</h3>
                        </Row>
                    </Col>
                    <Col lg={4} ref={el => elementRefs.current[2] = el} data-umami-name={"Support local restaurants value prop view"}>
                    <Row className="d-flex flex-column align-items-center text-center">
                        <img src={noSubscriptionIcon} style={{ height: "100px", width: "auto" }} alt="No Memberships or Subscription Required" />
                            <h3 className={'mt-2'}>No subscription plans</h3>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container className="FAQs d-grid gap-5">
                <Row ref={el => elementRefs.current[3] = el} data-umami-name={"FAQ section view"}>
                    <Col>
                        <h3 className="text-center">Frequently Asked Questions</h3>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6} ref={el => elementRefs.current[4] = el} data-umami-name={"FAQ1 view"}>
                        <Row><p><b>What is Upright Bites?</b></p></Row>
                        <Row><p>Upright Bites is a food delivery platform committed to free delivery and no hidden fees.</p></Row>
                    </Col>
                    <Col sm={6} ref={el => elementRefs.current[5] = el} data-umami-name={"FAQ2 view"}>
                        <Row><p><b>What sets Upright Bites apart from other food delivery services?</b></p></Row>
                        <Row><p>Our focus on free delivery and transparency sets us apart from competing services
                            like Uber Eats, Doordash, Grubhub or Seamless.</p></Row>
                    </Col>
                    <Col sm={6} ref={el => elementRefs.current[6] = el} data-umami-name={"FAQ3 view"}>
                        <Row><p><b>Where does Upright Bites source its food?</b></p></Row>
                        <Row><p>We partner with local restaurants to bring you a diverse and delicious menu, supporting
                            the culinary scene in your area.</p></Row>
                    </Col>
                    <Col sm={6} ref={el => elementRefs.current[7] = el} data-umami-name={"FAQ3=4 view"}>
                        <Row><p><b>How can Upright Bites afford to offer free delivery?</b></p></Row>
                        <Row><p>Upright Bites uses proprietary technology to streamline operations and complete deliveries more efficiently than our competitors. This allows us to provide free delivery without compromising on service or quality.</p></Row>
                    </Col>
                    <Col sm={6} ref={el => elementRefs.current[8] = el} data-umami-name={"FAQ5 view"}>
                        <Row><p><b>What areas does Upright Bites currently serve?</b></p></Row>
                        <Row><p>Upright Bites currently operates within select neighborhoods in New York City. Please enter your address on our homepage to see if we deliver to your area. If we don't serve your area yet, please check back soon as we are rapidly expanding!</p></Row>
                    </Col>
                    <Col sm={6} ref={el => elementRefs.current[9] = el} data-umami-name={"FAQ6 view"}>
                        <Row><p><b>What if I have an issue with my order?</b></p></Row>
                        <Row><p>We are committed to delivering a best in class experience to our customers. If anything should go wrong, please email us at <a className={'text-decoration-none text-body'} href={'mailto:help@uprightbites.com'}>help@uprightbites.com</a> or call/text us at <a className={'text-decoration-none text-body'} href='tel:646-535-7020'>646-535-7020</a> so that we can make it right.</p></Row>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default Home;