import React, {useState, useEffect} from 'react';
import {
    Container,
    Row,
    Col,
    Form,
    Button,
    Alert
} from "react-bootstrap";
import {Helmet} from "react-helmet";
import { getAuth, onAuthStateChanged, signOut, verifyBeforeUpdateEmail, updatePassword, sendEmailVerification  } from "firebase/auth";
import app from "../firebaseConfig";
import { useNavigate, Link, useLocation } from "react-router-dom";
import {EnvelopeCheck, CheckCircle} from "react-bootstrap-icons";

const Account = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("")
    const [newEmail, setNewEmail] = useState("")
    const [uid, setUid] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [validated, setValidated] = useState(false)
    const auth = getAuth(app);
    const user = auth.currentUser;
    const [userVerified, setUserVerified] = useState(null)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const reauthenticated = queryParams.get('reauthenticated');
    const field = queryParams.get('field');
    const status = queryParams.get('status');
    const error = queryParams.get('error');
    const newAccount = queryParams.get('newAccount');

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user && !user.isAnonymous) {
                setEmail(user.email);
                setUid(user.uid);
                setUserVerified(user.emailVerified)
            } else {
                navigate('/login')
            }
            // Cleanup subscription on unmount
            return () => unsubscribe();
        }, [auth, user]);
    });

    function handleUpdateEmail(event) {
        event.preventDefault();
        const user = auth.currentUser; // Get the current user inside the function
        const form = event.currentTarget;
        setValidated(true);
        if (form.checkValidity() === true) {
            verifyBeforeUpdateEmail(user, newEmail,)
                .then(() => {
                console.log('Email updated successfully to ' + newEmail);
                navigate('/account?field=email&status=updated')
                })
                .catch((error) => {
                    event.stopPropagation();
                    console.log('Failed to update verification email: ', error)
                    navigate('/account?field=email&error=true')
                });
            }
         else {
            event.stopPropagation();
        }
    };

    function handleUpdatePassword(event) {
        event.preventDefault();
        const user = auth.currentUser; // Get the current user inside the function
        const form = event.currentTarget;
        setValidated(true);
        if (form.checkValidity() === true) {
            updatePassword(user, newPassword,)
                .then(() => {
                    console.log('Password updated successfully');
                    navigate('/account?field=password&status=updated')
                })
                .catch((error) => {
                    event.stopPropagation();
                    console.log('Failed to change password: ', error)
                    navigate('/account?field=password&error=true')
                });
        }
        else {
            event.stopPropagation();
        }
    };

    function handleResendVerificationEmail() {
        sendEmailVerification(user)
            .then(() => {
                console.log('Verification email sent successfully')
                navigate('/account?field=emailVerification&status=sent')
            })
            .catch((error) => {
                console.log('Failed to send verification email: ', error)
                navigate('/account?field=emailVerification&error=true')
            });
    }


    function handleSignOut() {
        signOut(auth).then(() => {
            navigate('/login?signedout=true')
        }).catch((error) => {
            console.log(error)
        });
    }


        return (
        <Container>
            <Helmet>
                <title>Account : Upright Bites</title>
            </Helmet>
            <Row className="d-flex align-items-center justify-content-center mt-2">
                <Col>
                    <h1>Account Management</h1>
                    {newAccount === 'true' && (
                        <Alert variant={"success"}><EnvelopeCheck></EnvelopeCheck> Account created successfully. Please check your email to verify your account.</Alert>
                    )}
                </Col>
            </Row>
            <Row xs={"auto"} className="align-items-center">
                <Col xs={"6"} lg={4}>
                    <div className={"fw-bold"}>
                        Email
                    </div>
                    {error === true && field === 'email' && (
                        <div className={"text-danger ms-3"}>Failed to update email. Please try again.</div>
                    )}
                    {status === 'updated' && field === 'email' && (
                        <div className={"text-success ms-3"}><EnvelopeCheck></EnvelopeCheck> Verification email sent. </div>
                    )}
                    {reauthenticated === 'true' && field === 'email' ? (
                        <>
                        <Form noValidate validated={validated} onSubmit={handleUpdateEmail}>
                        <Form.Control required
                            type="email"
                            defaultValue={email}
                            onChange={(e) => setNewEmail(e.target.value)}
                        />
                        <Button size="sm" variant={"primary"} type={"submit"}>Save</Button> <Button variant={"secondary"} size="sm" onClick={() => navigate('/account')}>Cancel</Button>
                        </Form>
                        </>) : (
                        <div className={"ms-3"}>
                            {email}{' '}
                            {userVerified === false && (
                                <>
                                (<rel className={"text-warning"}>Not verified</rel>)<br />
                                    {error === true && field === 'emailVerification' && (
                                        <div className={"text-danger ms-3"}>Failed to send verification email. Please try again.</div>
                                    )}
                                    {status === 'sent' && field === 'emailVerification' && (
                                        <div className={"text-success ms-3"}><EnvelopeCheck></EnvelopeCheck> Verification email sent. </div>
                                    )}
                                    <Link className={"ms-3"} onClick={() => handleResendVerificationEmail()}>Resend verification email</Link>
                                </>
                                )}
                        </div>
                    )}
                </Col>
                <Col className={"ms-0 text-start"}>
                    {reauthenticated == null &&
                        <Button size="sm" variant={"outline-secondary"}  onClick={() => navigate("/login?reauthenticate=true&source=account&field=email")}>Edit</Button>
                    }
                </Col>
            </Row>
            <Row>
                <Col xs={"6"} lg={4}>
                    <div className={"fw-bold"}>Password</div>
                    {error === true && field === 'password' && (
                        <div className={"text-danger ms-3"}>Failed to update password. Please try again.</div>
                    )}
                    {status === 'updated' && field === 'password' && (
                        <div className={"text-success ms-3"}><CheckCircle></CheckCircle> Password updated. </div>
                    )}
                    {reauthenticated === 'true' && field === 'password' ? (
                        <>
                            <Form noValidate validated={validated} onSubmit={handleUpdatePassword}>
                                <Form.Control required
                                              type="password"
                                              onChange={(e) => setNewPassword(e.target.value)}
                                />
                                <Button size="sm" variant={"primary"} type={"submit"}>Save</Button> <Button variant={"secondary"} size="sm" onClick={() => navigate('/account')}>Cancel</Button>
                            </Form>
                        </>) : (
                        <div className={"ms-3"}>********</div>
                    )}
                </Col>
                <Col className={"ms-0 text-start"}>
                    {reauthenticated == null &&
                        <Button size="sm" variant={"outline-secondary"}  onClick={() => navigate("/login?reauthenticate=true&source=account&field=password")}>Edit</Button>
                    }
                </Col>
            </Row>
            <Row className={"p-2"}>
                <Col>
                <Button variant={"primary"} onClick={handleSignOut}>Sign Out</Button>
                </Col>
            </Row>
        </Container>
    );

}

export default Account;

