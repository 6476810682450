import React, {useEffect, useState} from 'react';
import {
    Container,
    Row,
    Col,
    Form,
    Button, Alert
} from "react-bootstrap";
import {Helmet} from "react-helmet";
import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    reauthenticateWithCredential,
    EmailAuthProvider,
    onAuthStateChanged
} from "firebase/auth";
import app from "../firebaseConfig";
import { useNavigate, Link, useLocation } from "react-router-dom";


const Login = () => {

    const [validated, setValidated] = useState(false);
    const [formSubmissionError, setFormSubmissionError] = useState(false)
    const [formSubmissionSuccess, setFormSubmissionSuccess] = useState(false)
    const auth = getAuth();
    const user = auth.currentUser;
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const signedout = queryParams.get('signedout');
    const reauthenticate = queryParams.get('reauthenticate');
    const field = queryParams.get('field');
    const passwordReset = queryParams.get('passwordReset');
    const source = location.state?.source;
    console.log('source is ' + source);


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user && queryParams.get('reauthenticate') !== "true" && !user.isAnonymous) {
                navigate('/account')
            }
            // Cleanup subscription on unmount
            return () => unsubscribe();
        }, [auth, user]);
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        setValidated(true);
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            //set email and password field values to variables
                    const email = form.email.value;
                    const password = form.password.value;
                    if (reauthenticate === 'true') {
                        const credential = EmailAuthProvider.credential(email, password);
                        reauthenticateWithCredential(user, credential).then(() => {
                            console.log('Reauthenticated successfully')
                            navigate("/account?reauthenticated=true&field="+field)
                        }).catch((error) => {
                            console.log('Failed to reauthenticate: ', error)
                            setFormSubmissionError(true)
                            form.password.value = ''
                        });
            } else{
                    signInWithEmailAndPassword(auth, email, password)
                    .then(() => {
                        if (source) {
                            navigate(source.toString())
                        } else {
                            navigate('/account')
                    }
                    })
                    .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        setFormSubmissionError(true)
                        form.password.value = ''
                    })
         }}
}


    return (
        <Container>
            <Helmet>
                <title>Login : Upright Bites</title>
                <style>
                    {`
                    .form-control.is-valid,
                    .was-validated .form-control:valid {
                    background-image: none;
                }
                `}
                </style>
            </Helmet>
            <Row className="d-flex mt-2">
                <Col lg={6}>
                    <h1>Login</h1>
                    {signedout === 'true' && (
                        <Alert variant="success">You have been signed out.</Alert>
                    )}
                        {reauthenticate === 'true' && (
                            <Alert variant="warning">Please login again in order to protect your account.</Alert>
                        )}
                    {passwordReset === 'true' && (
                        <Alert variant={"success"}>Your password has been reset. Please login with your new password.</Alert>
                    )}
                    <p>
                        Welcome back, please login to continue.<br />
                        <a href={'#'}
                           onClick={() => navigate('/register', {state: {source: source?.toString()}})}>Register</a> | <Link
                        to="/forgot-password">Forgot password?</Link>
                    </p>
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="formBasicEmail" >
                                <Form.Label>Email address</Form.Label>
                                <Form.Control required type="email" placeholder="Enter email" name={"email"}/>
                                <Form.Control.Feedback type="invalid">
                                    Please enter a valid email address.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control required type="password" placeholder="Password" name={"password"} />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a password.
                                </Form.Control.Feedback>
                            </Form.Group>
                            {formSubmissionError && (
                                <>
                                <Alert variant="danger">
                                    Invalid email or password. Please try again.
                                </Alert>
                                <br />
                                <br />
                                </>
                            )}
                            <Button className={"mb-3"} variant="primary" type="submit">
                                Login
                            </Button>
                        </Form>
                </Col>
            </Row>
        </Container>
);

}

export default Login;

