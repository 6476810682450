import {Row, Col, Container} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import React from "react";
import Footer from "./footer";
function UprightFooter() {

    return (
        <Container fluid style={{backgroundColor: '#fbfbf1'}}>
            <Container>
            <div className={'p-4'}>
            <Row>
            <Col className="text-center fw-bold">
                    Contact us
                </Col>
            </Row>
    <Row className={'justify-content-center'}>
        <Col xs="auto" className="text-center">
            <Link className={'text-decoration-none text-body'} to="mailto:help@uprightbites.com" data-umami-event={"Footer email link"}>help@uprightbites.com</Link><br />
            Call or text <Link className={'text-decoration-none text-body'} to={"tel:646-535-7020"} data-umami-event={"Footer call link"}>646-535-7020</Link>
        </Col>
        <Col xs="auto" >
            447 Broadway, 2nd Floor #1400<br />
            New York, NY 10013
                </Col>
        </Row>
            </div>
            <Row className={'p-2'}>
                <Col className="">
                    © 2024 Upright Bites, Inc.
                </Col>
                <Col className="text-end">
                     <Link to="/terms" className={'text-decoration-none text-secondary me-4'} data-umami-event={"Footer terms link"}>Terms of Use</Link> <Link to="/privacy" className={'text-decoration-none text-secondary'} data-umami-event={"Footer privacy policy link"}>Privacy Policy</Link>
                </Col>
            </Row>
        </Container>
        </Container>
    )
}

export default UprightFooter ;