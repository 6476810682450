import { initializeApp } from 'firebase/app';



const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "upright-bites.firebaseapp.com",
    projectId: "upright-bites",
    storageBucket: "upright-bites.appspot.com",
    messagingSenderId: "588855873071",
    appId: "1:588855873071:web:c74e3159489f570d8000da",
    measurementId: "G-SMH2J8WQ79"
};
const app = initializeApp(firebaseConfig);


export default app;