import React, { useState, useEffect } from "react";
import {useNavigate, useParams, Link, useLocation} from "react-router-dom";
import {Container, Row, Col, FormGroup, Form, Button, ProgressBar, Table, Modal, Spinner, Alert} from "react-bootstrap";
import {Helmet} from "react-helmet";

const DirectDineRestaurantAbout = () => {
    return (
        <Container>
            <Helmet>
                <title>Upright Bites: Direct Dine Program</title>
                <meta name="robots" content="noindex"/>
            </Helmet>
            <Row className="d-flex justify-content-center align-items-center">
                <Col lg={8}>
                        <h1>Direct Dine Program</h1>
                        <p>
                            Upright Bites is an innovative food delivery platform operating in New York City, committed
                            to transparency, equity, and exceptional customer service. We offer customers a flat $5 delivery fee
                            for all orders, unparalleled customer service through our dedicated full-time and part-time
                            delivery employees, and industry-leading low service fees for restaurants. <Link
                            to={'/partners/join'}>Learn more</Link>
                        </p>
                        <p>
                            Direct Dine enables customers to place orders directly through a restaurant's preferred online
                            platform while we handle the delivery. This allows customers to save on delivery fees,
                            support local restaurants, and promote fair delivery practices.
                        </p>
                    <p>
                        Restaurants are not charged any fees or commission from Upright Bites to participate in the program.
                    </p>
                        <p>
                            In the short term, we aim to fully integrate with our restaurant partners' point of sale
                            systems, facilitating customer orders entirely within our platform. The Direct Dine program
                            allows us to serve customers now while we build partnerships with neighborhood
                            restaurants.
                        </p>
                    <p>
                        If you would like to opt out of the Direct Dine program and have your restaurant removed from our platform, please contact us at <Link to={'mailto:help@uprightbites.com'}>help@uprightbites.com</Link>.
                    </p>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center bg-light align-items-center">
                <Col lg={8}>
                    <h2>Frequently Asked Questions</h2><br/>
                    <div>
                        <b>What is the Direct Dine program?</b>
                        <p>
                            The Direct Dine program enables customers to order directly from your restaurant, with
                            Upright Bites managing the delivery. This allows customers to save money on delivery fees
                            and is offered at no cost to your restaurant.
                        </p>
                    </div>
                    <div>
                        <b>How does Direct Dine benefit my restaurant?</b>
                        <p>
                            Direct Dine helps expose your restaurant to new customers and facilitates seamless
                            deliveries at no cost to you.
                        </p>
                    </div>
                    <div>
                        <b>How do I add my restaurant to Upright Bites?</b>
                        <p>
                            Complete our <Link to={'/partners/join'}>sign up form</Link> to get started and a member of
                            our team will reach out to you.
                        </p>
                    </div>
                    <div>
                        <b>How can I directly integrate my point of sales system with Upright Bites?</b>
                        <p>
                            We support all major point of sales systems, including Toast, Square and many more. Complete
                            our <Link to={'/partners/join'}>sign up form</Link> to get started with our full integration
                            and a member of our team will reach out to you.
                        </p>
                    </div>
                    <div>
                        <b>What are the fees for participating in Direct Dine?</b>
                        <p>
                            Restaurants are not charged any fees or commissions from Upright Bites for participating in
                            the Direct Dine program. Customers pay a $5 delivery fee for each order they place through
                            our platform.
                        </p>
                    </div>
                    <div>
                        <b>How do I remove my restaurant from Upright Bites?</b>
                        <p>
                            Contact us at <Link to={'mailto:help@uprightbites.com'}>help@uprightbites.com</Link> and we
                            will promptly remove your restaurant from our platform.
                        </p>
                    </div>
                    <div>
                        <b>How does Upright Bites determine which online ordering platform it direct customers to use to
                            place their order?</b>
                        <p>
                            We aim to direct customers to the platform that charges the lowest fees to the restaurant.
                            If we get that wrong, please contact us at <Link
                            to={'mailto:help@uprightbites.com'}>help@uprightbites.com</Link> and we will update
                            our system.
                        </p>
                    </div>
                    <div>
                        <b>Why is my restaurant no longer listed on Upright Bites?</b>
                        <p>
                            We periodically rotate the restaurants listed on our platform through the Direct Dine program and replace them with other neighborhood restaurants. If you would like a persistent listing on out platform with a direct point of sale system integration, please complete our <Link to={'/partners/join'}>sign up form</Link> and a member of our team will reach out to you.
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default DirectDineRestaurantAbout;