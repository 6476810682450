import React, { useState, useEffect } from 'react';
import {Container, Row, Col, Card, Button, ListGroup, Alert} from 'react-bootstrap';
import {useNavigate, Link, useLocation, useParams} from "react-router-dom";
import {CheckCircle, TagFill} from 'react-bootstrap-icons';
import {Helmet} from "react-helmet";
import {getAuth, onAuthStateChanged, signInAnonymously} from "firebase/auth";
import app from "../firebaseConfig";
import TiktokPixel from "tiktok-pixel";


function DirectDineOrderConfirmation() {
    const navigate = useNavigate()
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const paymentIntentId = params.get('payment_intent');
    const paymentIntentClientSecret = params.get('payment_intent_client_secret');
    const orderNo = params.get('order_number');
    const zeroDollarOrder = location.state?.zeroDollarOrder;
    let [paymentIntentData, setPaymentIntentData] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const auth = getAuth(app);
    const [user, setUser] = useState(auth.currentUser);
    const currentPath = window.location.pathname;
    const {restaurantId} = useParams();
    const [authError, setAuthError] = useState(false);
    let idToken

    TiktokPixel.pageView()
    TiktokPixel.track("PlaceAnOrder", {
        value: (paymentIntentData.amount_total * 0.01).toFixed(2),
        currency: "USD"
    })

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(auth.currentUser);
        });
        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [auth]);

    useEffect(() => {
        console.log('user is', user)
        const unsubscribe = async function () {
            if (user && paymentIntentId && paymentIntentClientSecret) {
                idToken = await user.getIdToken();
                console.log(idToken);
                const retrieveData = async () => {
                    const response = await fetch(`${process.env.REACT_APP_SERVICES_DOMAIN}/payment-intent-status/${paymentIntentId}/${paymentIntentClientSecret}`, {
                        headers: {
                            'Authorization': idToken,
                        }
                    });
                    if (response.status >= 400 && response.status < 500) {
                        setAuthError(true);
                        setIsLoading(false);
                        return;
                    }
                    const data = await response.json();
                    return data;
                }
                retrieveData(idToken)
                    .then(data => {
                        setPaymentIntentData(data);
                        setIsLoading(false);
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        setIsLoading(false); // Add this line
                    });
            }
            if (user && orderNo) {
                idToken = await user.getIdToken();
                console.log(idToken);
                const retrieveData = async () => {
                    const response = await fetch(`${process.env.REACT_APP_SERVICES_DOMAIN}/direct-dine-first-order-status/${orderNo}`, {
                        headers: {
                            'Authorization': idToken,
                        }
                    });
                    if (response.status >= 400 && response.status < 500) {
                        setAuthError(true);
                        setIsLoading(false);
                        return;
                    }
                    const data = await response.json();
                    return data;
                }
                retrieveData(orderNo)
                    .then(data => {
                        setPaymentIntentData(data);
                        setIsLoading(false);
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        setIsLoading(false); // Add this line
                        setAuthError(true)
                        return
                    });
            }
        };
        unsubscribe();
    }, [navigate, currentPath, user, auth]);

    if (isLoading) {
        return <Container><div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div></Container>;
    }
    if (authError) {
        return <Container><Alert variant={'danger'} className={'mt-3'}>Not authorized</Alert></Container>
    }
    if (paymentIntentData.status === 'succeeded' || paymentIntentData.status === 'complete') {
        return (
            <Container>
                <Helmet>
                    <title>Order confirmed : Upright Bites</title>
                </Helmet>
                <Row className={"mt-2"}>
                    <Col xs={2} md={1}><CheckCircle style={{ height: '100%', width: '100%' }}/></Col>
                    <Col>
                        <h1>Thank you!</h1>
                        <p className={'fw-bold'}>Direct Dine Order # {paymentIntentData.metadata.orderNo}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>We have received your order and are on the way to pick up and deliver your food. You will receive a confirmation email
                            shortly.</p>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <Card style={{ height: '100%' }}>
                            <Card.Header>Order from</Card.Header>
                                <Card.Body>
                                <p><div className={'fw-bold'}>{paymentIntentData.restaurantDetails.name}</div>
                                    {paymentIntentData.restaurantDetails.address1} <br />
                                    {paymentIntentData.restaurantDetails.address2 && paymentIntentData.restaurantDetails.address2} {paymentIntentData.restaurantDetails.address2 && <br />}
                                    {paymentIntentData.restaurantDetails.city}, {paymentIntentData.restaurantDetails.state} {paymentIntentData.restaurantDetails.zipcode}</p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6}>
                        <Card style={{ height: '100%' }}>
                            <Card.Header>Deliver to</Card.Header>
                            <Card.Body>
                                <p>{paymentIntentData.shipping.name}<br />
                                {paymentIntentData.shipping.address.line1}<br />
                                {paymentIntentData.shipping.address.line2 && paymentIntentData.shipping.address.line2} {paymentIntentData.shipping.address.line2 && <br />}
                                {paymentIntentData.shipping.address.city}, {paymentIntentData.shipping.address.state} {paymentIntentData.shipping.address.postal_code}</p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className={'justify-content-center mt-3 mb-3'}>
                    <Col xl={6}>
                <Card>
                    <Card.Header>Order details</Card.Header>
                <Row className={'ps-2'}>
                    <Col>
                        <p className={'fw-bold'}>Description</p>
                    </Col>
                    <Col>
                        <p className={'fw-bold'}>Price</p>
                    </Col>
                </Row>
                <Row className={'ps-2'}>
                    <Col>
                        <p>Delivery Fee</p>
                    </Col>
                    <Col>
                        <p>$5.00</p>
                    </Col>
                </Row>
                    {paymentIntentData.metadata.tip > 0 &&
                        <Row className={'ps-2'}>
                            <Col>
                                <p>Tip</p>
                            </Col>
                            <Col>
                                <p>{'$'}{Number(paymentIntentData.metadata.tip).toFixed(2)}</p>
                            </Col>
                        </Row>
                    }
                    {paymentIntentData.metadata.firstOrder &&
                        <Row className={'ps-2'}>
                            <Col>
                                <p><TagFill /> First order delivered free</p>
                            </Col>
                            <Col>
                                <p>-$5.00</p>
                            </Col>
                        </Row>
                    }
                    <ListGroup variant="flush">
                    <Row className={'mt-2'}>
                        <Col>
                            <p className={'fw-bold text-end'}>Total</p>
                        </Col>
                        <Col>
                            <p className={'fw-bold'}>{'$'}{(paymentIntentData.amount_total * 0.01).toFixed(2)}</p>
                        </Col>
                    </Row>
                    </ListGroup>
                </Card>
                    </Col>
                </Row>
                <Row className={'mt-5'}>
                    <Col className="text-center">
                        <Button variant={'outline-secondary'} as={Link} to={'/order'}>Hungry for more?</Button>
                    </Col>
                </Row>
            </Container>

        )
    }
    else {
        // navigate(`/direct-dine/${restaurantId}/payment`, {state: {paymentError: true} })

        }
}


export default DirectDineOrderConfirmation;
