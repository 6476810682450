import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import {Alert, Button} from 'react-bootstrap';
import React, {useState} from "react";

const PaymentForm = ({restaurantId}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [showButton, setShowButton] = useState(false);
    const [stripeSubmitError, setStripeSubmitError] = useState('')
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        setIsLoading(true);

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        console.log(`${process.env.REACT_APP_FRONT_END_DOMAIN}/direct-dine/${restaurantId}/confirmation`)

        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: `${process.env.REACT_APP_FRONT_END_DOMAIN}/direct-dine/${restaurantId}/confirmation`
            },
        });

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            const error = result.error.message
            setStripeSubmitError(error);
            setIsLoading(false)
        } else {
           console.log('success!')
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement onReady={e => setShowButton(true)} />
            {stripeSubmitError &&
                <Alert variant={'danger'} className={'mt-3'}>{stripeSubmitError} Please try again.</Alert>
            }
            {showButton &&
            <Button variant="primary" type={"submit"} className={`w-100 mt-3`} disabled={isLoading}>{isLoading ? 'Processing Payment..' : 'Complete Order'}</Button>
            }

        </form>
    )
};

export default PaymentForm;
