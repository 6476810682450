import React, {useState, useRef} from 'react';
import {useNavigate, Link } from "react-router-dom";
import {
    Container,
    Button,
    Tooltip,
    Overlay,
    Row,
    Col,
    Table,
    FormGroup,
    Form,
    InputGroup,
    Alert,
    Modal
} from "react-bootstrap";
import {Helmet} from "react-helmet";
import freeDeliveryIcon from '../assets/img/free-delivery-icon.png';
import noHiddenFeesIcon from '../assets/img/no-hidden-fees-icon.png';
import noSubscriptionIcon from '../assets/img/no-subscription-icon.png';
import positiveBrandImageIcon from '../assets/img/positive-brand-image-icon.png';
import customerLoyaltyIcon from '../assets/img/customer-loyalty-icon.png';
import localReachIcon from '../assets/img/local-reach-icon.png';

const PartnersPage = () => {
    const [validated, setValidated] = useState(false);
    const [formSubmissionError, setFormSubmissionError] = useState(false)
    const [formSubmissionSuccess, setFormSubmissionSuccess] = useState(false)
    const target = useRef(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        setValidated(true);
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            const formData = new FormData(form);
            const data = Object.fromEntries(formData);
            const response = await fetch(`${process.env.REACT_APP_SERVICES_DOMAIN}/create-lead`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            if (!response.ok) {
                window.umami.track('Form submission error');
                setFormSubmissionError(true)
            } else {
                //hide form
                setFormSubmissionSuccess(true)
                setFormSubmissionError(false)
                form.reset();
                setValidated(false);
            }
            // handle result
        }
    };

    const handleClose = () => setFormSubmissionSuccess(false);

        // Function to handle scroll event
function handleScroll() {
    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollPercentage = (scrollTop + windowHeight) / documentHeight * 100;

    if (scrollPercentage >= 25 && !window.hasScrolled25) {
        recordUmamiScrollEvent(25);
        window.hasScrolled25 = true;
    } else if (scrollPercentage >= 50 && !window.hasScrolled50) {
        recordUmamiScrollEvent(50);
        window.hasScrolled50 = true;
    } else if (scrollPercentage >= 75 && !window.hasScrolled75) {
        recordUmamiScrollEvent(75);
        window.hasScrolled75 = true;
    } else if (scrollPercentage >= 100 && !window.hasScrolled100) {
        recordUmamiScrollEvent(100);
        window.hasScrolled100 = true;
    }
}

function recordUmamiScrollEvent(percentage) {
    window.umami.track(`Scrolled ${percentage}%`);
}

// Add scroll event listener
window.addEventListener('scroll', handleScroll);

    return (
        <Container>
            <Helmet>
                <title>Restaurant Partners | Upright Bites</title>
                <meta name="description"
                      content="Add your restaraunt to Upright Bites. Increase your restaurant's revenue, build customer loyalty and enhance brand image."/>
            </Helmet>
                <Modal show={formSubmissionSuccess === true}  onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Form submitted</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Thank you for your interest in Upright Bites. A member of our team will be in touch shortly to discuss the next steps.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>Close</Button>
                    </Modal.Footer>
                </Modal>
            <Row>
                <Col>
                    <h1 className={"text-center p-5"}>Add your restaurant to Upright Bites</h1>
                    <p>Upright Bites is an innovative new food delivery platform serving customers in New York City.
                        Join our platform now and be at the forefront of a movement that prioritizes customer
                        satisfaction and challenges the norms of traditional food delivery services. With Upright Bites,
                        you'll not only gain access to a growing base of delighted diners but also contribute to
                        reshaping the industry landscape for the better</p>
                    <div className="text-center p-4">
                        <a href="#formSection">
                        <Button variant={"primary"} data-umami-event={"Get in touch button"}>Get in touch</Button>
                        </a>
                        </div>
                </Col>
            </Row>
            <Row className={"d-flex align-items-center justify-content-center word-wrap bg-light p-5"}>
                <Col>
                    <h4 className={"text-center"}>Why do customers choose Upright Bites?</h4>
                </Col>
            </Row>
            <Row className={"p-2 bg-light"}>
                <Col lg={4}>
                <div className={"text-center"}>
                <img src={freeDeliveryIcon} style={{ height: "100px", width: "auto" }} alt="Free Delivery on Every Order" className='mb-2' />
                    <p className={"fw-bold"}>Free delivery</p>
                    </div>
                    <p>Customers love that Upright Bites offers truly free delivery. No extra charges, just great food delivered straight to their door without any added cost.</p>
                </Col>
                <Col lg={4}>
                <div className={"text-center"}>
                    <img src={noHiddenFeesIcon} style={{ height: "100px", width: "auto"}} alt="No hidden fees" className={"mb-2"} />
                    <p className={"fw-bold"}>No hidden fees</p>
                    </div>
                    <p>Our customers value transparency. With Upright Bites, they never have to worry about surprise fees at checkout. What they see is exactly what they get.</p>
                </Col>
                <Col lg={4}>
                <div className={"text-center"}>
                <img src={noSubscriptionIcon} style={{ height: "100px", width: "auto" }} alt="No Memberships or Subscription Required" className='mb-2' />
                    <p className={"text-center fw-bold"}>No subscription plans</p>
                    </div>
                    <p>Customers appreciate the freedom of using Upright Bites without being tied to any subscriptions or memberships. They get the convenience they want, without the commitments they don’t.</p>
                </Col>
            </Row>
            <Row className={"d-flex align-items-center justify-content-center word-wrap p-5"}>
                <Col>
                    <h4 className={"text-center"}>What's in it for restaurants?</h4>
                </Col>
            </Row>
            <Row className={"p-2"}>
                <Col lg={4}>
                <div className={"text-center"}>
                    <img src={localReachIcon} style={{ height: "100px", width: "auto"}} alt="Increase local reach" className={"mb-2"} />
                    <p className={"fw-bold"}>Increase local reach</p>
                    </div>
                    <p>With Upright Bites' smaller delivery zones, your restaurant can stand out in a more focused area. This means quicker delivery times and better service for customers, which helps drive more orders and increases your visibility within your neighborhood.</p>
                </Col>
                <Col lg={4}>
                <div className={"text-center"}>
                    <img src={customerLoyaltyIcon} style={{ height: "100px", width: "auto"}} alt="Customer loyalty" className={"mb-2"} />
                    <p className={"fw-bold"}>Customer loyalty</p>
                    </div>
                    <p>Our fee free deliveries make your restaurant more appealing to customers, driving more frequent orders and fostering greater loyalty. By offering a cost-effective and reliable delivery experience, you encourage repeat business and build a dedicated customer base.</p>
                </Col>
                <Col lg={4}>
                <div className={"text-center"}>
                    <img src={positiveBrandImageIcon} style={{ height: "100px", width: "auto"}} alt="No hidden fees" className={"mb-2"} />
                    <p className={"text-center fw-bold"}>Positive brand image</p>
                    </div>
                    <p>Partnering with Upright Bites helps restaurants build a positive brand image in the community, as they’re associated with a service that values transparency, quality, and value.</p>
                </Col>
            </Row>
            <Row className={"d-flex align-items-center justify-content-center word-wrap text-center bg-light p-3 "}>
                <Col>
                    <h4>Transparent restaurant fee structure</h4>
                    <p>Our commitment to transparent fees extends to both our restaurant partners and customers.</p>
                </Col>
            </Row>
            <Row className={"d-flex align-items-center justify-content-center word-wrap bg-light"}>
            <Table>
                <thead>
                <tr>
                    <td className={"fst-italic bg-light"}>Fee type</td>
                    <td className={"fst-italic bg-light"}>Amount</td>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className={"bg-light"}>Merchant delivery order fee</td>
                    <td className={"bg-light"}>15% of the purchase price of each delivery order (excludes sales taxes)
                    <div className={"ms-3"}>*Waived for orders picked up by the customer</div>
                    </td>
                </tr>
                <tr>
                <td className={"bg-light"}>Merchant delivery processing fee</td>
                <td className={"bg-light"}>5% of the purchase price of each delivery order (excludes sales taxes)
                <div className={"ms-3"}>*Waived for orders picked up by the customer</div>
                </td>
                </tr>
                <tr>
                    <td className={"bg-light"}>Credit card processing fee</td>
                    <td className={"bg-light"}>2.9% of the purchase price + sales taxes and $0.30 per order</td>
                </tr>
                </tbody>
            </Table>
            </Row>
            <Row className={"d-flex align-items-center justify-content-center word-wrap"} id={"formSection"}>
                <Col xs={12} className={"text-center p-5"}>
                    <h4>Interested in learning more? Ready to sign up?</h4>
                    <p className={"text-center"}>Please fill out the information below and a member of our team will
                        contact you.</p>
                </Col>
                <Col>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Form.Label htmlFor={"restaurantContactName"}>Contact name*</Form.Label>
                        <Form.Control type={"text"} id={"restaurantContactName"} name={"restaurantContactName"} data-umami-event={"Contact name field"}
                                      required/>
                        <Form.Control.Feedback type="invalid">
                            Please provide a Contact name.
                        </Form.Control.Feedback>
                        <Form.Label className={"pt-2"} htmlFor={"restaurantName"} >Restaurant name*</Form.Label>
                        <Form.Control type={"text"} id={"restaurantName"} name={"restaurantName"} data-umami-event={"Restaurant name field"} required/>
                        <Form.Control.Feedback type="invalid">
                            Please provide the name of the restaurant.
                        </Form.Control.Feedback>
                        <Form.Label className={"pt-2"} htmlFor={"restaurantPhone"}>Phone number</Form.Label>
                        <Form.Control type={"text"} id={"restaurantPhone"} name={"restaurantPhone"} data-umami-event={"Phone field"} />
                        <Form.Label className={"pt-2"} htmlFor={"restaurantEmail"}>Email*</Form.Label>
                        <Form.Control type={"email"} id={"restaurantEmail"} name={"restaurantEmail"} data-umami-event={"Email field"} required/>
                        <Form.Control.Feedback type="invalid">
                            Please provide a valid email address.
                        </Form.Control.Feedback>
                        <Form.Label className={"pt-2"} htmlFor={"comments"}>Additional information or questions</Form.Label>
                        <Form.Control as="textarea" rows={3}  id={"comments"} name={"comments"} data-umami-event={"Comments field"}/>
                        <div className={"p-4 text-center"}><Button variant={"primary"} type={"submit"} data-umami-event={"Submit button"}>Submit</Button>
                        </div>
                        {formSubmissionError && <Alert variant={"danger"}>An error has occurred.</Alert>}
                    </Form>
                    </Col>
            </Row>

        </Container>
    )
};


export default PartnersPage;
