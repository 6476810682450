import React from 'react';
import {
    Container,
    Row,
    Col
} from "react-bootstrap";
import { Helmet } from "react-helmet";

const AboutPage = () => {

    return (
        <Container>
            <Helmet>
                <title>About : Upright Bites</title>
            </Helmet>
            <Row className="d-flex align-items-center justify-content-center mt-2">
                <Col>
                    <h1>About Us</h1>
                    <p>Upright Bites isn't just another food delivery platform. We're a passionate team dedicated to revolutionizing the food delivery experience in cities nationwide. Founded in 2023, we saw a need for change – free delivery, no hidden fees, and no subscriptions or memberships. Our mission is simple: to create a better food delivery platform.</p>

                        <p>How do we do it? By focusing on efficiency and transparency. We've combined AI with our expert logistical backgrounds to optimize the delivery process and reduce costs. This allows us to offer free delivery without cutting corners on service or compromising on quality.</p>

                        <p>For us, it's not just business – it's personal. We understand the importance of community and supporting local businesses. That's why we're committed to building strong partnerships with restaurants and engaging with our neighborhoods to create positive change.</p>

                        <p>Join us in our journey to reshape the food delivery landscape. Whether you're a restaurant owner looking for a reliable partner or a hungry customer craving convenience without breaking the bank, Upright Bites is here for you. Together, we can build a better future for food delivery.</p>
                </Col>
            </Row>
        </Container>
    );

}

export default AboutPage;

